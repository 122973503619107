import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const RedirectIcon = styled(({ color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...rest}>
    <g fill="none" fillRule="evenodd">
      <g fill={color || '#FFF'}>
        <g>
          <g>
            <path
              d="M31.625 40H5.042C2.26 40 0 37.74 0 34.958V8.375c0-2.781 2.26-5.042 5.042-5.042h17.416c.76 0 1.375.616 1.375 1.375 0 .76-.616 1.375-1.375 1.375H5.042c-1.263 0-2.292 1.029-2.292 2.292v26.583c0 1.264 1.029 2.292 2.292 2.292h26.583c1.263 0 2.292-1.028 2.292-2.292V17.542c0-.76.616-1.375 1.375-1.375s1.375.616 1.375 1.375v17.416c0 2.782-2.261 5.042-5.042 5.042"
              transform="translate(-576 -763) translate(335 750) translate(241 13)"
            />
            <g>
              <path
                d="M21.934 14.326c-.741 0-1.343-.602-1.343-1.343V2.687H10.296c-.742 0-1.343-.601-1.343-1.343 0-.741.601-1.343 1.343-1.343h11.638c.741 0 1.343.602 1.343 1.343v11.639c0 .741-.602 1.343-1.343 1.343"
                transform="translate(-576 -763) translate(335 750) translate(241 13) translate(16.667)"
              />
              <path
                d="M1.343 23.279c-.344 0-.688-.131-.95-.394-.524-.525-.524-1.375 0-1.9L20.985.393c.525-.524 1.375-.524 1.9 0 .524.525.524 1.375 0 1.9L2.293 22.885c-.263.263-.606.394-.95.394"
                transform="translate(-576 -763) translate(335 750) translate(241 13) translate(16.667)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
