import React from 'react'
import { Container, Text } from '../shared'
import { FacebookShareButton, RedditShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import { FacebookIcon, TwitterIcon, WhatsIcon, RedditIcon, ClipIcon, ShareIcon } from '../icons/tools/index'
import Translator from '../../i18n/translator'
import { useToasts } from 'react-toast-notifications'

const Share: React.FC<{ width?: string; height?: string }> = ({ width = '42px', height = '42px' }) => {
  const url = window.location.href
  const { addToast } = useToasts()
  const shareMobile = () => {
    return (
      navigator?.share &&
      navigator
        .share({
          title: 'Article',
          url: url,
        })
        .then(() => {
          console.log('Thanks for sharing!')
        })
        .catch((err) => console.log(err))
    )
  }
  const copy = () => {
    navigator.clipboard.writeText(url)
    addToast('Copied to clipboard', {
      appearance: 'success',
      autoDismiss: true,
    })
  }
  return (
    <>
      <Container display={['none', 'flex']}>
        <Container style={{ cursor: 'pointer' }} onClick={copy}>
          <ClipIcon width={width} height={height} />
        </Container>
        <TwitterShareButton url={url} style={{ marginLeft: '.5em' }}>
          <TwitterIcon width={width} height={height} />
        </TwitterShareButton>
        <FacebookShareButton url={url} style={{ marginLeft: '.5em' }}>
          <FacebookIcon width={width} height={height} />
        </FacebookShareButton>
        <WhatsappShareButton url={url} style={{ marginLeft: '.5em' }}>
          <WhatsIcon width={width} height={height} />
        </WhatsappShareButton>
        <RedditShareButton url={url} style={{ marginLeft: '.5em' }}>
          <RedditIcon width={width} height={height} />
        </RedditShareButton>
      </Container>
      <Container display={['flex', 'none']}>
        <Container
          onClick={shareMobile}
          style={{ padding: '8px 12px 7px', borderRadius: '5px', border: 'solid 1.5px #000000' }}
          display="flex"
          alignItems="center"
        >
          <Text fontSize="1em" fontFamily="SofiaProSemiBold">
            {Translator('article.share')}
          </Text>
          <ShareIcon width="20px" marginLeft="1em" />
        </Container>
      </Container>
    </>
  )
}

export default Share
