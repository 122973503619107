import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const LogoCOZ = styled(({ color,height, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="127" height={height || '37'} {...rest} viewBox="0 0 127 37">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill={color || '#131514'}
            d="M113.519 9.637L113.519 12.869 102.367 24.086 113.519 24.086 113.519 27.584 97.699 27.584 97.699 24.542 109 13.136 98.312 13.136 98.312 9.637 113.519 9.637"
            transform="translate(-179 -52) translate(179 52)"
          />
          <path
            stroke={color || '#131514'}
            strokeWidth=".5"
            d="M113.519 9.637L113.519 12.869 102.367 24.086 113.519 24.086 113.519 27.584 97.699 27.584 97.699 24.542 109 13.136 98.312 13.136 98.312 9.637z"
            transform="translate(-179 -52) translate(179 52)"
          />
          <path
            fill={color || '#131514'}
            d="M81.06 23.818c-2.729 0-5.198-2.495-5.198-5.224s2.47-5.198 5.198-5.198c2.73 0 5.198 2.469 5.198 5.198 0 2.73-2.469 5.224-5.198 5.224zm0-14.295c-4.886 0-9.097 4.21-9.097 9.097 0 4.886 4.211 9.097 9.097 9.097 4.886 0 9.097-4.21 9.097-9.097 0-4.886-4.21-9.097-9.097-9.097z"
            transform="translate(-179 -52) translate(179 52)"
          />
          <g>
            <path
              fill={color || '#131514'}
              d="M60.33 21.453h4.292c-1.183 3.639-4.575 6.264-8.56 6.264-4.96 0-8.996-4.08-8.996-9.097 0-5.016 4.036-9.097 8.997-9.097 3.984 0 7.351 2.6 8.533 6.212h-4.292c-.72-1.585-2.468-2.573-4.241-2.573-2.828 0-5.09 2.47-5.09 5.458 0 3.015 2.262 5.458 5.09 5.458 1.799 0 3.547-1.065 4.266-2.625M29.155 27.625h-7.288l7.244-7.244 3.644 3.644-3.6 3.6zm-18.176 3.6l-7.245.044 7.288-7.288-7.244-7.244 3.6-3.6L18.223 23.98l-7.244 7.244zm3.644-25.333l3.6 3.6 3.644-3.644 3.644 3.644-3.644 3.644 3.6 3.6-3.6 3.6L11.022 9.493l3.6-3.6zM0 36.657h36.498V.159H0v36.498z"
              transform="translate(-179 -52) translate(179 52)"
            />
            <path
              stroke={color || '#131514'}
              strokeWidth="1.9"
              d="M1.002 35.72L125.363 35.72 125.363 1.059 1.002 1.059z"
              transform="translate(-179 -52) translate(179 52)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
