import styled from 'styled-components'
import {layout, space} from 'styled-system'

export const DefaultLogo = styled(({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="183" height="183" viewBox="0 0 183 183" {...rest}>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g fill="#1F2B2D">
            <path
              d="M168.589 183H14.412C6.452 183 0 176.548 0 168.588V14.412C0 6.452 6.452 0 14.412 0h154.177C176.549 0 183 6.452 183 14.412v154.176c0 7.96-6.451 14.412-14.411 14.412"
              transform="translate(-88 -442) translate(88 442)"
            />
          </g>
          <path
            fill="#FFF"
            d="M36.806 36H145.19299999999998V148H36.806z"
            transform="translate(-88 -442) translate(88 442)"
          />
          <path
            fill="#1F2B2D"
            d="M129.436 124H104l25.283-25L142 111.576 129.436 124zM65.5 136.845L40 137l25.655-25.724-25.5-25.569L52.826 73 91 111.276l-25.5 25.57zm13.172-89.691l12.674 12.673L104.172 47 117 59.827l-12.828 12.827 12.674 12.674L104.172 98 66 59.827l12.672-12.673zM27 155h128V27H27v128z"
            transform="translate(-88 -442) translate(88 442)"
          />
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
