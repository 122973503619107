import styled from 'styled-components'
import { border, color, flexbox, layout, position, shadow, space, typography } from 'styled-system'
import { LogoCOZ } from '../icons'
import Button from '../shared/button'

export const Wrapper = styled.div.attrs(() => { })`
  ${position}

`

export const Container = styled.div.attrs(() => { })`
  box-sizing: border-box;
  ${color};
  ${flexbox}
  ${layout}
  ${space}
  ${position}
`
export const Group = styled.div.attrs(() => { })`
  ${flexbox}
  ${layout}
  a {
    text-decoration: none;
    color: #000;
    display: flex;
  }
`

export const Logo = styled(LogoCOZ).attrs(() => { })`
  ${layout}
`

export const CustomButton = styled(Button).attrs(() => { })`
  ${layout}
  ${position}
`
export const ButtonMobile = styled.div.attrs(() => { })`
  ${layout}
  ${color}
  ${border}
  ${space}
  ${flexbox}
`

export const Title = styled.h2.attrs(() => { })`
  ${space}
  ${typography}
`

export const ContainerDescription = styled.div.attrs(() => { })`
  ${color}
  ${space}
  ${layout}
  ${shadow}
  ${position}
`

export const TextDescription = styled.p.attrs(() => { })`
  ${color}
  ${typography}
`

export const ContainerLinks = styled.div.attrs(() => { })`
  box-sizing: border-box;
  ${shadow}
  ${color};
  ${flexbox}
  ${layout}
  ${space}
  ${position}
`

export const Link = styled.a.attrs(() => { })`
  ${layout}
  ${border}
  ${space}
`
