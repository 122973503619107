import {Tool} from './Tool'
import {Type} from 'class-transformer'

export class Article {
  slug?: string
  title?: string
  shortTitle?: string
  author?: string
  @Type(() => Date)
  published?: string
  @Type(() => Date)
  lastUpdated?: string
  targetAudience?: string
  estimatedTime?: number
  partySize?: number
  level?: string
  relatedTools: Tool[] = []
  linkedContent: string[] = []
  relatedArticles: string[] = []
  discussionLink?: string
  briefSummary?: string
  body?: string
}