import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const MenuIcon = styled(({ color }: any) => (
  <svg width="33" height="21" viewBox="0 0 33 21">
    <defs>
      <path id="wixfdo0kma" d="M0 0L32.586 0 32.586 3.231 0 3.231z" />
      <path id="8bn6p83khc" d="M0 0L28.299 0 28.299 3.231 0 3.231z" />
      <path id="jrbr67lote" d="M0 0L28.299 0 28.299 3.231 0 3.231z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g transform="translate(-318 -28) translate(25 28) translate(293) translate(0 8.885)">
                <mask id="der9o4pnmb" fill="#fff">
                  <use href="#wixfdo0kma" />
                </mask>
                <path
                  fill={color || '#000'}
                  d="M30.978 0H1.608C.72 0 0 .723 0 1.615s.72 1.616 1.608 1.616h29.37c.888 0 1.608-.724 1.608-1.616 0-.892-.72-1.615-1.608-1.615"
                  mask="url(#der9o4pnmb)"
                />
              </g>
              <g transform="translate(-318 -28) translate(25 28) translate(293) translate(2.144)">
                <mask id="qxt9kp6fid" fill="#fff">
                  <use href="#8bn6p83khc" />
                </mask>
                <path
                  fill={color || '#000'}
                  d="M26.69 0H1.609C.72 0 0 .723 0 1.615s.72 1.616 1.608 1.616H26.69c.888 0 1.608-.724 1.608-1.616C28.299.723 27.579 0 26.69 0"
                  mask="url(#qxt9kp6fid)"
                />
              </g>
              <g transform="translate(-318 -28) translate(25 28) translate(293) translate(2.144 17.77)">
                <mask id="2qbhqoutdf" fill="#fff">
                  <use href="#jrbr67lote" />
                </mask>
                <path
                  fill={color || '#000'}
                  d="M26.69 0H1.609C.72 0 0 .723 0 1.615s.72 1.616 1.608 1.616H26.69c.888 0 1.608-.724 1.608-1.616C28.299.723 27.579 0 26.69 0"
                  mask="url(#2qbhqoutdf)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
