import styled from 'styled-components'
import { display, position, left, right, top, bottom, zIndex, layout, color, space, background, typography, flexbox, border, shadow } from 'styled-system'

export const Container = styled.div.attrs(() => { })`
  ${display}
  ${position}
  ${left}
  ${right}
  ${top}
  ${bottom}
  ${zIndex}
  ${layout}
  ${space}
  ${background}
  ${color}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
`

export const Text = styled.div.attrs(() => { })`
  ${layout}
  ${space}
  ${typography}
  ${flexbox}
  ${color}
`

export const Title = styled.h1.attrs(() => { })`
  ${layout}
  ${space}
  ${typography}
  ${color}
`

export const Subtitle = styled.h2.attrs(() => { })`
  ${layout}
  ${space}
  ${typography}
  ${color}
`

export const Description = styled.p.attrs(() => { })`
  ${layout}
  ${space}
  ${typography}
  ${color}
`
