import React from 'react'
import { useHistory } from 'react-router'
import Translator from '../../i18n/translator'
import { Article } from '../../model/Article'
import Share from '../article-share'
import { ClockIcon, FlagIcon, PeopleIcon } from '../icons'
import { ArrowLeft } from '../icons/arrowLeft'
import { Container, Title, Description, Description as Span } from '../shared'

const Tag: React.FC<{ text: string }> = ({ text }) => (
  <Container bg="black" borderRadius="55px" margin=".3em .5em">
    <Description
      color="white"
      textAlign="center"
      padding="0.2em 1em"
      margin="0"
      fontFamily="SofiaProSemibold"
      fontSize="1em"
    >
      {text}
    </Description>
  </Container>
)

const Header: React.FC<{ article: Article }> = ({ article }) => {
  const history = useHistory()

  if (!article?.title) {
    history.push('/')
    return null
  }

  const convertDate = () => {
    const date = new Intl.DateTimeFormat('en-US').format(new Date(article?.lastUpdated || ''))
    const split = date.split('/')
    return `${split[0]}.${split[1]}.${split[2]}`
  }

  const widthBarColor = document.getElementById('details-header')?.getClientRects()[0]?.width

  return (
    <>
      <Container
        display={['flex', 'none']}
        width={['100vw', '65%']}
        marginTop={['0', '5em']}
        marginLeft={['0', '0']}
        marginBottom="1em"
      >
        {article?.relatedTools?.map((i, index) => (
          <Container
            key={index}
            width={`${100 / article.relatedTools.length}%`}
            height={['1.1em', '6px']}
            bg={i.color}
          />
        ))}
      </Container>
      <Container
        paddingX="10%"
        display="flex"
        flexDirection="column"
        alignItems={['flex-start', 'center']}
        justifyContent="center"
        minWidth="80%"
        width={['80vw', 'initial']}
        position="relative"
        style={{ boxSizing: 'border-box' }}
      >
        <Container
          display={['none', 'flex']}
          width={['80vw', widthBarColor || 0]}
          marginTop={['0', '5em']}
          marginLeft={['0', '0']}
        >
          {article?.relatedTools?.map((i, index) => (
            <Container
              key={index}
              width={`${100 / article.relatedTools.length}%`}
              height={['1.1em', '6px']}
              bg={i.color}
            />
          ))}
        </Container>
        <Container display={['flex', 'none']} alignItems="center" position="absolute" right="-6vw" top="0">
          <ArrowLeft onClick={() => history.push("/")} />
          <Container width="85vw" marginRight="-5vw" height="1px" bg="warmGrey" />
        </Container>
        <Container display={['flex', 'none']} alignItems="center" marginTop={['3em', '1.5em']}>
          <ClockIcon width="1em" height="1em" mr="0.5em" />
          <Description fontSize=".8em" color="blueyGrey" margin="0 0 0">
            {article.estimatedTime} {Translator('article.estimatedTime')}
          </Description>
        </Container>
        <Title
          fontFamily="SofiaProBold"
          textAlign={['left', 'center']}
          fontSize={['2.2em', '3.5em']}
          margin={['0', '.5em 0 0']}
          lineHeight={['36px', '64px']}
        >
          {article.title}
        </Title>
        <Container display="flex" marginY="1.5em" flexWrap="wrap" marginLeft={['-.5em', '0']}>
          <Tag text={article.slug || ''} />
        </Container>
        <Description display="flex" alignItems="center" margin="0">
          {Translator('article.by')}{' '}
          <Span margin="0 .3em" fontFamily="SofiaProBold">
            {article.author}
          </Span>{' '}
        </Description>
        <Description display="flex" alignItems="center" margin="0">
          {Translator('article.lastUpdated')}{' '}
          <Span margin="0 .3em" fontFamily="SofiaProBold">
            {convertDate()}
          </Span>
        </Description>
        <Container
          display="flex"
          alignItems="center"
          borderBottom="1px solid"
          borderTop="1px solid"
          borderColor="warmGrey"
          width={['100%', 'initial']}
          paddingY="1em"
          marginY="1.8em"
          id="details-header"
        >
          <Container display="flex" alignItems="center">
            <PeopleIcon width="1.5em" height="1em" mr="0.25em" />
            <Description fontSize=".8em" color="blueyGrey" margin="0 0 0 .1em">
              {' '}
              {article.targetAudience}{' '}
            </Description>
          </Container>
          <Container marginLeft="2em" display="flex" alignItems="center">
            <FlagIcon width="1em" height="1em" />
            <Description fontSize=".8em" color="blueyGrey" margin="0 0 0 .1em">
              {' '}
              {article.level}{' '}
            </Description>
          </Container>
          <Container marginLeft="2em" display={['none', 'flex']} alignItems="center">
            <ClockIcon width="1em" height="1em" mr="0.5em" />
            <Description fontSize=".8em" color="blueyGrey" margin="0 0 0 .1em">
              {' '}
              {article.estimatedTime} {Translator('article.estimatedTime')}
            </Description>
          </Container>
        </Container>
        {widthBarColor && (
          <Container display="flex" flexDirection="column" alignItems="center">
            <Share />
          </Container>
        )}
      </Container>
    </>
  )
}

export default Header
