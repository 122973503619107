import styled from 'styled-components'
import {layout, space} from 'styled-system'

export const RightArrowCircle = styled(({...rest}) => (
  <svg width="62px" height="62px" viewBox="0 0 62 62" {...rest}>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="COZ---00---devex-page-V2" transform="translate(-1912.000000, -1401.000000)">
        <g id="Group-15-Copy-4" transform="translate(1913.000000, 1402.000000)">
          <g id="Group-14" stroke="#979797">
            <circle id="Oval" cx="30" cy="30" r="30"/>
          </g>
          <path d="M38.9813462,30.2720049 C38.9813462,30.800759 38.7769635,31.328868 38.3658383,31.7311753 L27.178934,42.7413381 C26.3595679,43.5477591 25.0311459,43.5477591 24.2112554,42.7413381 C23.3918892,41.9349171 23.3918892,40.6274828 24.2112554,39.8205457 L33.9160902,30.2694243 L24.2112554,20.7179158 C23.3918892,19.9114948 23.3918892,18.6035445 24.2112554,17.7971234 C25.0311459,16.9907024 26.3595679,16.9907024 27.178934,17.7971234 L38.3658383,28.8078024 C38.7769635,29.2151417 38.9813462,29.7432507 38.9813462,30.2720049" id="Fill-1" fill="#000000"/>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() =>{})`
  ${layout}
  ${space}
`
