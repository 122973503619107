import React, { useEffect, useState } from 'react'
import { MinimizeIcon } from '../icons/minimize'
import { Container, Description, Subtitle, Container as Ball } from '../shared'

interface List {
  title: string
  id: string
  distance: number
}

const ArticleMenu: React.FC<{ title: string; markdown: string; mobile?: boolean; setMenuLeft: any }> = ({
  title,
  setMenuLeft,
  markdown,
  mobile,
}) => {
  const [list, setList] = useState<any>([])
  const [selected, setSelected] = useState('')
  const [forceSelected, setForceSelected] = useState('')
  const [scroll, setScroll] = useState(0)
  const [open, setOpen] = useState(true)
  const [click, setClick] = useState(false)
  const [subtitle, setSubtitle] = useState<any>([])
  const [openSub, setOpenSub] = useState('')
  const getScroll = (e: any) => {
    setScroll(e?.path?.[1]?.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', getScroll)
    const titles = Array.prototype.slice.call(document.querySelectorAll('.markdown h1')).map((i) => {
      return { title: i.innerText, id: i.id, distance: i.offsetTop }
    })
    const subtitles = Array.prototype.slice.call(document.querySelectorAll('.markdown h2')).map((i) => {
      return { title: i.innerText, id: i.id, distance: i.offsetTop }
    })

    setList(titles)
    const array: any = []
    titles.map((item, index) =>
      subtitles.map((sub) => {
        if (sub.distance < titles[index + 1]?.distance && sub.distance > item.distance) {
          return array.push({ ...item, sub })
        }
        return null
      })
    )
    setSubtitle(array)
  }, [markdown])

  useEffect(() => {
    list.find((item: List) => {
      if (click) {
        return null
      }
      if (openSub === item.id) {
        subtitle.find((sub: any) => {
          if (sub.title === item.title) {
            scroll + 400 > sub.sub.distance && setSelected(sub.sub.id)
          }
          return null
        })
        return null
      }
      scroll + 400 > item.distance && setSelected(item.id)
      return null
    })
    // eslint-disable-next-line
  }, [scroll, list])

  const convertText = (text: string, size: number) => {
    if (text.length <= size) {
      return text.slice(0, size)
    }
    return `${text.slice(0, size)}...`
  }

  const handleScroll = (item: any) => {
    setClick(true)
    setForceSelected(item.id)
    setTimeout(() => {
      setSelected(item.id)
      setClick(false)
      setForceSelected('')
    }, 1000)
    setMenuLeft(false)
    return document.getElementById(item.id)?.scrollIntoView()
  }

  const verifyActive = (id: string) => {
    if (forceSelected) {
      return forceSelected === id ? '' : 'blueyGrey'
    }
    return selected === id ? '' : 'blueyGrey'
  }

  return (
    <Container
      top={!open ? '50vh' : '35vh'}
      position={['initial', 'fixed']}
      marginLeft="1em"
      style={{ transition: '1s' }}
    >
      <Ball
        width="50px"
        height="50px"
        bg={open ? 'black' : 'transparent'}
        borderRadius="100px"
        display={['none', 'flex']}
        alignItems="center"
        justifyContent="center"
        marginLeft="-25px"
        style={{ cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
        position="relative"
        border="1px solid"
        borderColor="black"
      >
        <MinimizeIcon color={open ? 'white' : 'black'} />
      </Ball>
      <Subtitle width="90%" display={['flex', 'none']} fontFamily="SofiaProSemibold" fontSize="1.3em" marginLeft=".5em">
        {title}
      </Subtitle>
      {open && (
        <Container
          borderLeft="1px solid"
          borderColor="warmGrey"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width={['90%', '20vw']}
        >
          <Subtitle display={['none', 'flex']} fontFamily="SofiaProSemibold" fontSize="1.3em" marginLeft=".5em">
            {title}
          </Subtitle>
          {list.map((item: List, index: number) => (
            <>
              <Container
                display="flex"
                alignItems={[
                  !index ? 'flex-start' : index === list.length - 1 ? 'flex-end' : 'center',
                  !index ? 'flex-start' : index === list.length - 1 ? 'flex-end' : 'center',
                ]}
                style={{ cursor: 'pointer' }}
                marginTop={!index ? 0 : '.3em'}
                marginBottom={index === list.length - 1 && 0}
              >
                <Ball
                  onClick={() => (item.id === openSub ? setOpenSub('') : setOpenSub(item.id))}
                  width="14px"
                  height="14px"
                  bg={openSub === item.id ? 'black' : 'background'}
                  borderRadius="50px"
                  marginLeft="-9px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border="1px solid"
                  borderColor="warmGrey"
                >
                  <MinimizeIcon
                    width="8px"
                    color={openSub === item.id ? 'white' : 'black'}
                    style={openSub !== item.id ? { transform: 'rotate(90deg)' } : {}}
                  />
                </Ball>
                <Description
                  color={verifyActive(item.id)}
                  onClick={() => (selected !== item.id ? handleScroll(item) : {})}
                  fontSize="1em"
                  margin="0px"
                  key={index}
                  marginLeft=".5em"
                  marginTop={!index && '-.3em'}
                  marginBottom={index === list.length - 1 && '-.3em'}
                >
                  {convertText(item.title, mobile ? 20 : 27)}
                </Description>
              </Container>
              {openSub === item.id &&
                subtitle.map(
                  (i: any, index: number) =>
                    i.title === item.title && (
                      <Description
                        color={verifyActive(i.sub.id)}
                        fontSize=".8em"
                        onClick={() => (selected !== i.sub.id ? handleScroll(i.sub) : {})}
                        key={index}
                        margin="0px"
                        marginLeft="1.5em"
                        my=".2em"
                        style={{ cursor: 'pointer' }}
                      >
                        - {convertText(i?.sub?.title, mobile ? 20 : 27)}
                      </Description>
                    )
                )}
            </>
          ))}
        </Container>
      )}
    </Container>
  )
}

export default ArticleMenu
