import styled from 'styled-components'
import { layout, flexbox, color, space, position, border, right, top } from 'styled-system'

export const Circle = styled.div.attrs(() => {})`
  ${space}
  ${layout}
  ${flexbox}
  ${color}
  ${position}
  ${border}
  cursor: pointer;
`

export const Divisor = styled.div.attrs(() => {})`
  ${layout}
  ${color}
  ${position}
  ${right}
  ${border}
  ${top}
`

