import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const ArticleIcon = styled(({ iconColor, ...rest }) => (
  <svg width="23" height="23" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.023 2.625C2.784 2.625 2.591 2.821 2.591 3.062V10.937C2.591 11.179 2.784 11.375 3.023 11.375H15.977C16.216 11.375 16.409 11.179 16.409 10.937V3.062C16.409 2.822 16.216 2.625 15.977 2.625H3.023ZM15.977 14H3.023C1.356 14 0 12.626 0 10.937V3.062C0 1.374 1.356 0 3.023 0H15.977C17.644 0 19 1.374 19 3.062V10.937C19 12.627 17.644 14 15.977 14ZM3.023 20.679C2.784 20.679 2.591 20.879 2.591 21.125V39.875C2.591 40.121 2.784 40.321 3.023 40.321H15.977C16.216 40.321 16.409 40.121 16.409 39.875V21.125C16.409 20.879 16.216 20.679 15.977 20.679H3.023ZM15.977 43H3.023C1.356 43 0 41.598 0 39.875V21.125C0 19.402 1.356 18 3.023 18H15.977C17.644 18 19 19.402 19 21.125V39.875C19 41.598 17.644 43 15.977 43ZM27.023 31.625C26.784 31.625 26.591 31.821 26.591 32.063V39.938C26.591 40.178 26.784 40.375 27.023 40.375H39.977C40.216 40.375 40.409 40.179 40.409 39.938V32.063C40.409 31.821 40.216 31.625 39.977 31.625H27.023V31.625ZM39.977 43H27.023C25.356 43 24 41.626 24 39.938V32.063C24 30.373 25.356 29 27.023 29H39.977C41.644 29 43 30.374 43 32.063V39.938C43 41.626 41.644 43 39.977 43ZM27.023 2.679C26.784 2.679 26.591 2.879 26.591 3.125V21.875C26.591 22.121 26.784 22.321 27.023 22.321H39.977C40.216 22.321 40.409 22.121 40.409 21.875V3.125C40.409 2.879 40.216 2.679 39.977 2.679H27.023ZM39.977 25H27.023C25.356 25 24 23.598 24 21.875V3.125C24 1.402 25.356 0 27.023 0H39.977C41.644 0 43 1.402 43 3.125V21.875C43 23.598 41.644 25 39.977 25Z"
      fill={iconColor || '#231F20'}
    />
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
