import React from 'react'
import Translator from '../../i18n/translator'
import { LogoCOZ } from '../icons'
import * as Styled from './styled'

const Footer: React.FC = () => {
  const year = new Date().getFullYear()
  return (
    <Styled.Container
      backgroundColor="charcoalGrey"
      display="flex"
      alignItems="center"
      padding={['1em 5%', '1% 7.5%']}
      width="100%"
    >
      <LogoCOZ color="white" height={28} />
      <Styled.Text fontSize=".8em" color="steelGrey">
        {year}
        {Translator('home.footer.copyright')}
        <Styled.Marker textDecoration="underline" color="brightSkyBlue" href="mailto:contact@coz.io" target="_blank">
          {Translator('home.footer.title')}
        </Styled.Marker>
      </Styled.Text>
    </Styled.Container>
  )
}

export default Footer
