import React, {useEffect, useState} from 'react'
import {Article} from '../model/Article'
import {plainToClass} from 'class-transformer'
import {Tool} from '../model/Tool'
import marked from 'marked'

export interface Dictionary<T> {
  [key: string]: T
}

export interface IntDictionary<T> {
  [key: number]: T
}

export interface GlobalContextValue {
  articles: Dictionary<Article>,
  tools: Dictionary<Tool>
  levels: IntDictionary<string>
  loadMarkdown: (s?: string) => Promise<string | undefined>
}

const loadArticles = async (tools: Dictionary<Tool>, levels: IntDictionary<string>): Promise<Dictionary<Article>> => {
  const slugs = require('../static/articles/articlesList.json').list as string[]

  const dict: Dictionary<Article> = {}

  slugs.forEach((slug) => {

    const json = require(`../static/articles/${slug}.json`)
    const loadedTools: Tool[] = []

    const readTools = json.relatedTools as string[]
    readTools.forEach((it) => {
      loadedTools.push(tools[it])
    })

    const readLevel = json.level as number

    const article = plainToClass(Article, json)
    article.relatedTools = loadedTools
    article.level = levels[readLevel]

    dict[slug] = article
  })

  return dict
}

const loadTools = async (): Promise<Dictionary<Tool>> => {
  const json = require('../static/articles/tools.json')
  const dict: Dictionary<Tool> = {}

  Object.entries(json).forEach(([key, value]) => {
    dict[key] = plainToClass(Tool, value)
  })

  return dict
}

const loadLevels = async (): Promise<IntDictionary<string>> => {
  const json = require('../static/articles/levels.json')
  const dict: IntDictionary<string> = {}

  Object.entries(json).forEach(([key, value]) => {
    dict[Number(key)] = value as string
  })

  return dict
}

const loadArticleBody = async (slug: string) => {
  const path = require(`../static/articles/${slug}.md`)

  marked.setOptions({
    renderer: new marked.Renderer(),
    highlight: function(code, lang) {
      const hljs = require('highlight.js');
      const language = hljs.getLanguage(lang) ? lang : 'python';
      return hljs.highlight(code, { language }).value;
    }
  })

  return marked(await (await fetch(path.default)).text())
}

export const GlobalContext = React.createContext<GlobalContextValue>({articles: {}, tools: {}, levels: {}, loadMarkdown: async () => undefined})

export const GlobalProvider: React.FC = ({children}) => {

  const [tools, setTools] = useState<Dictionary<Tool>>({})
  const [levels, setLevels] = useState<IntDictionary<string>>({})
  const [articles, setArticles] = useState<Dictionary<Article>>({})

  const initContext = async () => {
    const tools = await loadTools()
    const levels = await loadLevels()
    const articles = await loadArticles(tools, levels)

    console.log("Loading global context")

    setArticles(articles)
    setTools(tools)
    setLevels(levels)
  }

  const loadMarkdown = async (slug?: string) => {
    // Returns undefined if slug is undefined
    if (!slug) return

    // Context hasn't loaded yet (refreshed page)
    if (!articles[slug]) return

    // Loads and returns article body if not present
    if (!articles[slug].body) {
      console.log('Reads body from file. This should only happen once')
      const body = await loadArticleBody(slug)
      articles[slug].body = body

      setArticles(articles)
      return body
    }

    return articles[slug].body
  }

  useEffect(() => {
    initContext()
  }, [])

  return <GlobalContext.Provider value={{articles: articles, tools: tools, levels: levels, loadMarkdown: loadMarkdown}}>
    {children}
  </GlobalContext.Provider>
}