import styled from 'styled-components'
import { border, color, flexbox, layout, space, typography } from 'styled-system'

export const JoinButton = styled.a.attrs(() => {})`
  outline: none;
  border: none;
  text-decoration: none;
  ${color};
  ${typography}
  ${layout}
  ${border}
  ${space}
  ${flexbox}
`
