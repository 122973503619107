const theme = {
  colors: {
    brightSkyBlue: '#00cbff',
    duckEggBlue: '#f7fdfe',
    steelGrey: '#798688',
    charcoalGrey: '#39424d',
    black: '#000',
    grapefruit: '#ff6161',
    background: '#f7fdfe',
    paleGrey: '#f6f9fc',
    warmGrey: '#979797',
    blueyGrey: '#8892a3',
    greyCode: '#e2eaea'
  },
}
export default theme
