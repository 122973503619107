import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const TwitterIcon = styled(({...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" {...rest}>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-997 -895) translate(942 861) translate(56 35)">
            <circle cx="20" cy="20" r="20" stroke="#000" />
            <path
              fill="#231F20"
              d="M32.933 12.368c-.967.401-1.997.667-3.07.797 1.104-.625 1.947-1.607 2.344-2.79-1.03.582-2.168.993-3.38 1.222C27.849 10.61 26.454 10 24.933 10c-2.95 0-5.326 2.268-5.326 5.048 0 .4.035.784.123 1.15-4.431-.204-8.352-2.215-10.986-5.278-.46.755-.73 1.62-.73 2.55 0 1.748.95 3.298 2.368 4.195-.857-.016-1.697-.251-2.409-.622v.055c0 2.453 1.848 4.49 4.27 4.959-.433.112-.906.166-1.397.166-.34 0-.685-.018-1.009-.086.69 1.998 2.65 3.468 4.98 3.515-1.813 1.343-4.115 2.153-6.608 2.153-.437 0-.856-.019-1.276-.07C9.294 29.177 12.093 30 15.11 30c9.809 0 15.171-7.692 15.171-14.36 0-.223-.008-.438-.02-.652 1.059-.711 1.948-1.599 2.672-2.62"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
