import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const FacebookIcon = styled(({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" {...rest}>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-1053 -895) translate(942 861) translate(112 35)">
            <circle cx="20" cy="20" r="20" stroke="#000" />
            <g fill="#231F20" fill-rule="nonzero">
              <path
                d="M10.119 11.092v-3.85H6.948V5.23c0-.517.166-.863.779-.863h2.392V0h-2.67c-2.725 0-4.84 2.357-4.84 5.345v1.896h-2.89v3.851h2.89V20h4.339v-8.908h3.17z"
                transform="translate(14.352 10)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
