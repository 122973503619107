import React, { useContext, useState } from 'react'
import { Article } from '../../model/Article'
import { Description } from '../banner/styled'
import { ToolsIcon, ArticleIcon, ShareIcon, DiscussIcon } from '../icons/tools/index'
import { Container, Text } from '../shared'
import * as Styled from './styled'
import * as Icons from '../icons/tools/index'
import Share from '../article-share'
import Translator from '../../i18n/translator'
import { GlobalContext } from '../../context/GlobalContext'
import { RedirectIcon } from '../icons'

const Circle = ({ children, noDivisor, active, id }: any) => {
  const height = document.getElementById(`menu-${id}`)?.getClientRects()[0]?.height

  return (
    <Container display="flex" flexDirection="column" alignItems="center" height="100%">
      <Styled.Circle
        border="1px solid"
        borderColor="steelGrey"
        borderRadius="50%"
        width={[25, 48]}
        height={[25, 48]}
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        bg={active && 'black'}
      >
        {children}
      </Styled.Circle>
      {active && (
        <Container display="flex" top="24px" alignItems="center" position="absolute" right="48px">
          <Styled.Divisor
            width="5px"
            height="5px"
            bg="black"
            borderRadius="50px"
          />
          <Styled.Divisor  width="13vw" height="1px" bg="steelGrey" />
        </Container>
      )}
      <Styled.Divisor width="1px" minHeight="1em" height={[height, 10]} bg={noDivisor ? 'transparent' : 'steelGrey'} />
    </Container>
  )
}

const ArticleMenuRight: React.FC<{ article: Article; mobile?: boolean }> = ({ article, mobile }) => {
  const [open, setOpen] = useState('')
  const globalContext = useContext(GlobalContext)
  const related = article.relatedArticles.map((i) => globalContext.articles[i])
  const links: any = {
    Neon: 'https://docs.coz.io/neo3/neon-js',
    Boa: 'https://docs.coz.io/neo3/boa/index.html',
    Dora: 'https://dora.coz.io/documentation/index.html',
    Mamba: 'https://docs.coz.io/neo3/mamba/index.html',
  }

  return (
    <Container
      position={['initial', 'fixed']}
      top="35vh"
      right="1em"
      display="flex"
      flexDirection="column"
      alignItems={['flex-start', 'flex-end']}
      marginLeft={['2em', 0]}
    >
      <Menu
        mobile={mobile}
        open={open}
        setOpen={setOpen}
        Icon={ToolsIcon}
        id="tool"
        title={Translator('article.menu.tools')}
      >
        {article?.relatedTools?.map((item, index: number) => {
          const Logo = item.title ? Icons[item.title] : undefined
          const url = item.title ? links[item?.title.toString()] : ''
          return (
            Logo && (
              <a href={url} target="_blank" rel="noreferrer">
                {' '}
                <Logo article paddingLeft="1em" key={index} />{' '}
              </a>
            )
          )
        })}
      </Menu>
      <Menu
        mobile={mobile}
        open={open}
        setOpen={setOpen}
        Icon={ArticleIcon}
        id="article"
        title={Translator('article.menu.articles')}
      >
        {related.map((i) => (
          <a href={`/article/${i.slug}`} style={{ textDecoration: 'none' }} rel="noreferrer">
            <Text color="blueyGrey" marginLeft=".5em">
              - {i.shortTitle}
            </Text>
          </a>
        ))}
      </Menu>
      <Menu
        mobile={mobile}
        open={open}
        setOpen={setOpen}
        Icon={DiscussIcon}
        id="discuss"
        title={Translator('article.menu.discuss')}
      >
        <a
          href={article.discussionLink}
          rel="noreferrer"
          target="_blank"
        >
          <Styled.Circle
            border="1px solid"
            borderColor="black"
            borderRadius="50%"
            width={40}
            height={40}
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
          >
            <RedirectIcon width="20" heigth="20" color="black" />
          </Styled.Circle>
        </a>
      </Menu>
      <Menu
        mobile={mobile}
        open={open}
        setOpen={setOpen}
        Icon={ShareIcon}
        id="share"
        title={Translator('article.share')}
        noDivisor
      >
        {open && <Share />}
      </Menu>
     
    </Container>
  )
}

const Menu = ({ setOpen, open, Icon, id, title, noDivisor, children, mobile }: any) => {
  const show = !!mobile || open === id
  const share = id === 'share' && mobile
  const url = window.location.href
  const shareMobile = () => {
    if (!share) {
      return null
    }
    return (
      navigator?.share &&
      navigator
        .share({
          title: 'Article',
          url: url,
        })
        .then(() => {
          console.log('Thanks for sharing!')
        })
        .catch((err) => console.log(err))
    )
  }
  return (
    <Container
      position="relative"
      display="flex"
      alignItems={['flex-start', 'center']}
      onMouseEnter={() => !mobile && setOpen(id)}
      onMouseLeave={() => !mobile && setOpen('')}
      width={!mobile && open === id && '16vw'}
      justifyContent="flex-end"
      flexDirection={['row-reverse', 'row']}
    >
      {show && (
        <Container
          display="flex"
          flexDirection="column"
          position={['initial', 'absolute']}
          right="5vw"
          top="30px"
          width={['100%', '11vw']}
          marginLeft={['1em', '0']}
          id={`menu-${id}`}
        >
          <Description
            onClick={shareMobile}
            style={!!share ? { textDecoration: 'underline' } : {}}
            fontSize="1em"
            fontFamily="SofiaProSemibold"
            margin="0"
            marginLeft=".1em"
          >
            {title}
          </Description>
          {children}
        </Container>
      )}
      <Circle id={id} active={open === id} noDivisor={noDivisor}>
        <Icon width={[15, 'auto']} heigth={[15, 'auto']} iconColor={open === id && 'white'} />
      </Circle>
    </Container>
  )
}

export default ArticleMenuRight
