import styled from 'styled-components'
import { layout, border, color, flexbox, background, space, typography, position } from 'styled-system'
import Button from '../shared/button'

export const Container = styled.div.attrs(() => { })`
  ${layout}
  ${border}
  ${color}
  ${background}
  ${position}
`
export const BoxLeft = styled.div.attrs(() => { })`
  ${layout}
  ${color}
`
export const Content = styled.div.attrs(() => { })`
  ${layout}
  ${flexbox}
  ${background}
`
export const Article = styled.div.attrs(() => { })`
  ${space}
  ${layout}
  ${flexbox}
`
export const Title = styled.h1.attrs(() => { })`
  ${typography}
  ${layout}
  ${space}
`
export const Subtitle = styled.h2.attrs(() => { })`
  ${typography}
  ${space}
`
export const Description = styled.p.attrs(() => { })`
  ${color}
  ${layout}
  ${typography}
  ${space}
`
export const Line = styled.div.attrs(() => { })`
  ${color}
  ${layout}
  ${space}
`
export const CustomButton = styled(Button).attrs(() => { })`
  ${layout}
  ${space}
  ${typography}
`
