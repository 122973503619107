import styled from 'styled-components'
import { layout, color, space, flexbox, maxWidth } from 'styled-system'

export const Card = styled.div.attrs(() => { })`
  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.2);
  outline: none;
  cursor: pointer;
  ${layout}
  ${space}
  ${color}
  ${flexbox}
  ${maxWidth}
`

export const Line = styled.div.attrs(() => { })`
  ${layout}
  ${color}
  ${space}
`
