import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ArticleBody from '../../components/article-body'
import Header from '../../components/article-header'
import ArticleMenu from '../../components/article-menu'
import ArticleMenuRight from '../../components/article-menu-right'
import Share from '../../components/article-share'
import { MinimizeIcon } from '../../components/icons/minimize'
import { Container } from '../../components/shared'
import { GlobalContext } from '../../context/GlobalContext'
import Translator from '../../i18n/translator'
import * as Styled from './styled'

const ArticleScreen: React.FC<{ match: { params: { slug: string } } }> = ({
  match: {
    params: { slug },
  },
}) => {
  const globalContext = useContext(GlobalContext)
  const article = globalContext.articles[slug]
  const [markdown, setMarkdown] = useState('')
  const [menuRight, setMenuRight] = useState(false)
  const [menuLeft, setMenuLeft] = useState(false)
  const loadMarkdown = async () => {
    const md = await globalContext.loadMarkdown(slug)
    setMarkdown(md ?? '')
  }

  useEffect(() => {
    loadMarkdown()
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container display="flex" justifyContent="space-around" bg="background">
      <title>{article?.title || ''}</title>
      <Container width="20%" display={['none', 'flex']}>
        <ArticleMenu setMenuLeft={setMenuLeft} markdown={markdown} title={article?.title || ''} />
      </Container>

      {!menuLeft && <ButtonMenuMobile menu={menuLeft} setMenu={setMenuLeft} position="fixed" top="50vh" left=".5em" />}
      <>
        {menuLeft && (
          <Container
            position="fixed"
            backgroundColor="rgba(255,255,255,.7)"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            onClick={() => setMenuLeft(false)}
            zIndex="10"
          />
        )}
        <Container
          display={['flex', 'none']}
          position="fixed"
          width="80vw"
          height="100vh"
          left={menuLeft ? '0' : '-80vw'}
          top="0"
          bg="duckEggBlue"
          flexDirection="column"
          padding="1em"
          style={{ boxSizing: 'border-box', transition: '1s' }}
          zIndex="99"
          boxShadow="0 6px 12px 0 rgba(0,0,0,.2)"
        >
          {menuLeft && (
            <ButtonMenuMobile
              marginLeft="-10px"
              menu={menuLeft}
              setMenu={setMenuLeft}
              position="absolute"
              top="50vh"
              left="80vw"
            />
          )}
          <Container
            display="flex"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="warmGrey"
            marginBottom="3vh"
            paddingBottom="1em"
          >
            <a href="https://coz.io/" target="_blank" rel="noreferrer" style={{ display: 'flex' }}>
              <Styled.Logo width={['4.5em', '7.8em']} />
            </a>
            <Link
              to="/"
              style={{
                textDecoration: 'none',
                color: '#000',
                display: 'flex',
              }}
            >
              <Styled.Title marginLeft=".7em" my="0" fontSize={['1.2em', '1.7em']} fontFamily="SofiaProBold">
                {Translator('home.header.title')}
              </Styled.Title>
            </Link>
          </Container>
          <ArticleMenu setMenuLeft={setMenuLeft} mobile markdown={markdown} title={article?.title || ''} />
        </Container>
      </>

      <Container width="60%" display="flex" flexDirection="column" alignItems="center">
        <Header article={article} />
        <ArticleBody markdown={markdown} setMarkdown={setMarkdown} />
        <Container display="flex" flexDirection="column" alignItems="center" marginY="2em">
          <Share />
        </Container>
      </Container>
      <>
        {menuRight && (
          <Container
            position="fixed"
            backgroundColor="rgba(255,255,255,.7)"
            top="0"
            left="0"
            width="100vw"
            height="100vh"
            onClick={() => setMenuRight(false)}
          />
        )}
        <Container
          display={['flex', 'none']}
          position="fixed"
          width="80vw"
          height="100vh"
          right={menuRight ? '0' : '-80vw'}
          top="0"
          bg="duckEggBlue"
          flexDirection="column"
          padding="1em"
          style={{ boxSizing: 'border-box', transition: '1s' }}
          zIndex="99"
          boxShadow="0 6px 12px 0 rgba(0,0,0,.2)"
        >
          {menuRight && (
            <ButtonMenuMobile
              marginLeft="-25px"
              menu={menuRight}
              setMenu={setMenuRight}
              position="absolute"
              top="50vh"
            />
          )}
          <Container
            display="flex"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="warmGrey"
            marginBottom="15vh"
            paddingBottom="1em"
          >
            <a href="https://coz.io/" target="_blank" rel="noreferrer" style={{ display: 'flex' }}>
              <Styled.Logo width={['4.5em', '7.8em']} />
            </a>
            <Link
              to="/"
              style={{
                textDecoration: 'none',
                color: '#000',
                display: 'flex',
              }}
            >
              <Styled.Title marginLeft=".7em" my="0" fontSize={['1.2em', '1.7em']} fontFamily="SofiaProBold">
                {Translator('home.header.title')}
              </Styled.Title>
            </Link>
          </Container>
          <ArticleMenuRight mobile article={article} />
        </Container>
      </>

      {!menuRight && (
        <ButtonMenuMobile
          marginLeft="-25px"
          menu={menuRight}
          setMenu={setMenuRight}
          position="fixed"
          top="50vh"
          right=".5em"
        />
      )}
      <Container width="15%" display={['none', 'flex']}>
        <ArticleMenuRight article={article} />
      </Container>
    </Container>
  )
}

const ButtonMenuMobile = ({ menu, setMenu, ...rest }: any) => (
  <Container
    onClick={() => setMenu(!menu)}
    display={['flex', 'none']}
    width="20px"
    height="20px"
    border="1px solid"
    borderColor={!menu && 'warmGrey'}
    borderRadius="100px"
    alignItems="center"
    justifyContent="center"
    bg={menu && 'black'}
    {...rest}
  >
    <MinimizeIcon color={!menu && 'black'} width="10px" />
  </Container>
)
export default ArticleScreen
