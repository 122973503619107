import styled from 'styled-components'
import { layout, flexbox, color, space, typography, position } from 'styled-system'

export const Container = styled.div.attrs(() => {})`
  ${space}
  ${layout}
  ${flexbox}
  ${color}
  ${position}
  box-sizing: border-box;
`

export const Text = styled.div.attrs(() => {})`
  ${color}
  ${typography}
`

export const Marker = styled.a.attrs(() => {})`
  ${color}
  ${typography}
`
