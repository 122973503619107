import styled from "styled-components";
import { Container } from "../shared";


export const Wrapper = styled(Container)`
    pre code {
        display: inline-block;
        width: 100%;
        overflow: auto;
        background-color: ${({ theme }) => theme.colors.greyCode};
        padding: 1%;
    }
    p img {
        width: 100%;
    }
    h1 {
        @media(max-width: 768px) {
            font-size: 1.5em;
        }
    }
`