import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const ClipIcon = styled(({ ...rest }) => (
  <svg width="42" height="42" viewBox="0 0 42 42" {...rest}>
    <defs>
      <path id="mktzbf3i8a" d="M0 0L12 0 12 12.8 0 12.8z" />
      <path id="se0k2z59dc" d="M0 0L12 0 12 13.6 0 13.6z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-941 -895) translate(942 861) translate(0 35)">
            <circle cx="20" cy="20" r="20" stroke="#000" />
            <g>
              <g transform="translate(10 10) translate(0 7.2)">
                <mask id="ps6t54uxkb" fill="#fff">
                  <use href="#mktzbf3i8a" />
                </mask>
                <path
                  fill="#231F20"
                  d="M8.243 8.134L5.9 10.446c-.971.959-2.544.959-3.515 0-.97-.958-.97-2.51 0-3.468l4.687-4.625c.97-.957 2.543-.957 3.514 0 .323.32.848.32 1.171 0 .324-.319.324-.837 0-1.156-1.617-1.596-4.24-1.596-5.857 0L1.213 5.822c-1.617 1.596-1.617 4.184 0 5.78 1.618 1.597 4.24 1.597 5.859 0L9.414 9.29c.324-.32.324-.837 0-1.156-.323-.32-.848-.32-1.171 0"
                  mask="url(#ps6t54uxkb)"
                />
              </g>
              <g transform="translate(10 10) translate(8)">
                <mask id="0vq6gyjgzd" fill="#fff">
                  <use href="#se0k2z59dc" />
                </mask>
                <path
                  fill="#231F20"
                  d="M10.832 1.228C9.275-.41 6.751-.41 5.194 1.228L2.488 4.073c-.31.327-.31.858 0 1.185.312.328.817.328 1.128 0l2.706-2.845c.934-.982 2.449-.982 3.383 0 .934.983.934 2.575 0 3.557l-4.962 5.216c-.934.983-2.448.983-3.382 0-.311-.327-.816-.327-1.127 0-.312.328-.312.859 0 1.186 1.556 1.637 4.08 1.637 5.637 0l4.961-5.216c1.557-1.637 1.557-4.291 0-5.928"
                  mask="url(#0vq6gyjgzd)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
