import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const WhatsIcon = styled(({...rest}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" {...rest}>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-1109 -895) translate(942 861) translate(168 35)">
            <circle cx="20" cy="20" r="20" stroke="#000" />
            <path
              fill="#231F20"
              fill-rule="nonzero"
              d="M29.944 19.742c0 5.381-4.396 9.743-9.818 9.743-1.722 0-3.339-.44-4.746-1.212L9.944 30l1.773-5.227c-.924-1.515-1.411-3.256-1.409-5.03 0-5.381 4.396-9.743 9.819-9.743 5.423 0 9.817 4.362 9.817 9.742zm-10-8.142c-4.632 0-8.4 3.589-8.4 8 0 1.75.595 3.371 1.6 4.69l-1.049 2.971 3.228-.984c1.373.865 2.98 1.325 4.622 1.323 4.631 0 8.4-3.588 8.4-8 0-4.411-3.769-8-8.4-8h-.001zm5.166 10.51c-.06-.097-.217-.156-.453-.272-.236-.116-1.398-.678-1.614-.756-.216-.077-.374-.116-.532.117-.157.232-.61.755-.748.91-.138.156-.275.176-.512.059-.236-.117-.996-.362-1.899-1.153-.702-.616-1.176-1.376-1.314-1.609-.137-.233-.014-.358.104-.474.107-.104.236-.27.355-.407.118-.135.157-.232.236-.387.079-.155.04-.29-.02-.407-.06-.116-.532-1.259-.729-1.724-.196-.465-.393-.388-.53-.388-.139 0-.296-.019-.453-.019-.158 0-.414.058-.63.29-.217.234-.827.795-.827 1.939 0 1.143.846 2.248.965 2.402.117.155 1.633 2.577 4.035 3.507 2.4.93 2.4.62 2.834.581.432-.039 1.397-.562 1.595-1.104.196-.543.196-1.008.137-1.105z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
