import React, { useContext } from 'react'
import Banner from '../../components/banner'
import { Container } from '../../components/shared'
import ArticleCarousel from '../../components/article-carousel'
import { GlobalContext } from '../../context/GlobalContext'
import background from './background.svg'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const HomeScreen: React.FC = () => {
  const globalContext = useContext(GlobalContext)

  const articles = Object.values(globalContext.articles)

  return (
    <>
      <title>COZ Dojo</title>
      <Carousel showArrows={false} showStatus={false} >
        <Banner article={articles[0]} />
        <Banner article={articles[1]} />
      </Carousel>
      <Container bg="duckEggBlue" px={['2em', '2em', '2em', '14em']} paddingY={['2em', '5em']} position="relative">
        <Container position="absolute" left="0" bottom="0" width="15%">
          <img src={background} style={{ width: '100%' }} alt="bg" />
        </Container>
        <Container mx={[0, 0, 0, '-1em']}>
          <ArticleCarousel title="Trending Content" articles={articles} />
        </Container>
      </Container>
    </>
  )
}

export default HomeScreen
