import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const MinimizeIcon = styled(({ ...rest }) => (
  <svg width="22" height="4" viewBox="0 0 32 4" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 4H2C0.896 4 0 3.104 0 2C0 0.896 0.896 0 2 0H30C31.104 0 32 0.896 32 2C32 3.104 31.104 4 30 4Z"
      fill={rest.color || 'white'}
    />
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
