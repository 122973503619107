import React from 'react'
import { Wrapper } from './styled'
import './marked.css'
import './github.css';

const ArticleBody = ({ markdown }: any) => {
  return (
    <>
      <Wrapper
        width={['85vw', '100%']}
        paddingX="3%"
        style={{ boxSizing: 'border-box' }}
        className="markdown"
        dangerouslySetInnerHTML={{
          __html: markdown,
        }}
      />
      <div></div>
    </>
  )
}

export default ArticleBody
