import React, { useRef, useState } from 'react'
import SwiperCore from 'swiper'
import { Swiper } from 'swiper/react'
import { SwiperSlide } from 'swiper/react'

import { Article } from '../../model/Article'
import { Container, Text } from '../shared'
import ArticleCard from '../article-card'
import { LeftArrowCircle, RightArrowCircle } from '../icons'

import 'swiper/swiper.scss'

const SCREEN_SM = 640
const SCREEN_MD = 768
const SCREEN_LG = 1024
const SCREEN_XL = 1280
const SCREEN_2XL = 1408
const SCREEN_3XL = 1792
const SCREEN_4XL = 2048

const CARD_HEIGHT = '16em'

const ArticleCarousel: React.FC<{
  title?: string
  articles: Article[]
}> = ({ title, articles }) => {
  const containerRef = useRef()

  const [swiperController, setSwiper] = useState<SwiperCore | null>(null)
  const [, setCurrentSlide] = useState(0)

  return (
    <Container position={'relative'}>
      <Text fontSize={['1.5em', '3em']} fontWeight={'bold'} textAlign={['start', 'center']} mb={['0.5rem', '3rem']}>
        {title}
      </Text>

      <Container
        display={['block', 'none']}
        background="#979797"
        width={['100vw', '100%']}
        height="1px"
        mb={['0', '1.5rem']}
      />

      <Container ref={containerRef} display="flex" flexDirection="row">
        <Container display={['none', 'block']} position={'absolute'} zIndex={1} left={-100} bottom={'8em'}>
          {!swiperController?.isBeginning ? (
            <LeftArrowCircle onClick={() => swiperController?.slidePrev()} />
          ) : (
            <Container />
          )}
        </Container>

        {articles.length === 0 ? (
          <></>
        ) : (
          <Swiper
            direction="horizontal"
            grabCursor={true}
            slidesPerView={'auto'}
            spaceBetween={32}
            breakpoints={{
              // when device is mobile
              [SCREEN_SM]: {
                slidesPerView: 'auto',
                spaceBetween: 32,
              },
              // when device is tablet
              [SCREEN_MD]: {
                slidesPerView: 'auto',
                spaceBetween: 32,
              },
              // when device is desktop
              [SCREEN_LG]: {
                slidesPerView: 'auto',
                spaceBetween: 32,
              },
              // when device is desktop
              [SCREEN_XL]: {
                slidesPerView: 2,
                spaceBetween: 32,
              },
              // when device is large desktop
              [SCREEN_2XL]: {
                slidesPerView: 3,
                spaceBetween: 32,
              },
              // when device is big desktop
              [SCREEN_3XL]: {
                slidesPerView: 4,
                spaceBetween: 32,
              },
              // when device is huge desktop
              [SCREEN_4XL]: {
                slidesPerView: 5,
                spaceBetween: 32,
              },
            }}
            onBeforeInit={(swiper) => setSwiper(swiper)}
            onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
            onResize={(swiper) => swiper.updateSlides()}
          >
            {articles.map((it, i) => (
              <SwiperSlide key={i}>
                <Container px={[0, 0, 0, '1em']}>
                  <ArticleCard height={CARD_HEIGHT} article={it} />
                </Container>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <Container display={['none', 'block']} position={'absolute'} zIndex={1} right={-100} bottom={'8em'}>
          {!swiperController?.isEnd ? (
            <RightArrowCircle onClick={() => swiperController?.slideNext()} />
          ) : (
            <Container />
          )}
        </Container>
      </Container>
    </Container>
  )
}

export default ArticleCarousel
