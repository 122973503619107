import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const Boa = styled(({ article, ...rest }) => (
  <svg width={article ? '70' : '90'} height="41" viewBox="0 0 106 41" {...rest}>
    <defs>
      <path id="gh0sb3so8a" d="M0.101 0.155L32.526 0.155 32.526 13.47 0.101 13.47z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#1F2B2D"
              d="M36.85 40H3.15C1.41 40 0 38.59 0 36.85V3.15C0 1.41 1.41 0 3.15 0h33.7C38.59 0 40 1.41 40 3.15v33.7c0 1.74-1.41 3.15-3.15 3.15"
              transform="translate(-1865 -190) translate(1865 190.203)"
            />
            <path
              fill="#00CBFF"
              d="M10.083 21.722L20 31.639 29.917 21.722 23.319 15.124 26.598 11.845 23.279 8.526 16.681 15.124 23.279 21.722 20 25.001 13.402 18.403 10.083 21.722"
              transform="translate(-1865 -190) translate(1865 190.203)"
            />
          </g>
          <path
            fill="#1F2B2D"
            d="M64.514 20.122c0-2.57-1.859-4.374-4.21-4.374-2.078 0-4.32 1.613-4.32 4.374 0 2.735 2.05 4.457 4.32 4.457 2.351 0 4.21-1.914 4.21-4.457zm2.406.028c0 4.02-2.898 6.671-6.398 6.671-1.832 0-3.69-.82-4.538-2.296v2.023h-2.38V6.807h2.38v8.968c.847-1.504 2.652-2.27 4.566-2.27 3.5 0 6.37 2.598 6.37 6.645z"
            transform="translate(-1865 -190) translate(1865 190.203)"
          />
          <g transform="translate(-1865 -190) translate(1865 190.203) translate(72.506 13.351)">
            <mask id="jmat43pf0b" fill="#fff">
              <use href="#gh0sb3so8a" />
            </mask>
            <path
              fill="#1F2B2D"
              d="M11.092 6.771c0-2.624-1.969-4.374-4.32-4.374-2.324 0-4.265 1.75-4.265 4.374 0 2.653 1.941 4.457 4.265 4.457 2.351 0 4.32-1.804 4.32-4.457zm-10.991 0C.1 2.725 3.136.155 6.772.155c3.637 0 6.699 2.57 6.699 6.616 0 4.047-3.062 6.7-6.699 6.7-3.636 0-6.671-2.653-6.671-6.7zM30.149 6.771c0-2.761-2.242-4.374-4.348-4.374-2.324 0-4.183 1.804-4.183 4.374 0 2.543 1.86 4.457 4.183 4.457 2.297 0 4.348-1.722 4.348-4.457zM32.527.428v12.769H30.15v-2.023c-.848 1.476-2.707 2.296-4.54 2.296-3.499 0-6.397-2.652-6.397-6.671 0-4.047 2.87-6.644 6.37-6.644 1.914 0 3.72.765 4.567 2.27V.427h2.378z"
              mask="url(#jmat43pf0b)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`

export const BoaLogo = styled(({ ...rest }) => (
  <svg width="43px" height="43px" viewBox="0 0 43 43" {...rest}>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="COZ---00---devex-page-V2" transform="translate(-1116.000000, -1294.000000)">
        <g id="Group-11-Copy" transform="translate(1116.000000, 1294.000000)">
          <path
            d="M38.8420811,42.1623083 L3.32040969,42.1623083 C1.48656004,42.1623083 -8.64019956e-14,40.6757483 -8.64019956e-14,38.8418987 L-8.64019956e-14,3.32040969 C-8.64019956e-14,1.48656004 1.48656004,-8.64019956e-14 3.32040969,-8.64019956e-14 L38.8420811,-8.64019956e-14 C40.6759307,-8.64019956e-14 42.1623083,1.48656004 42.1623083,3.32040969 L42.1623083,38.8418987 C42.1623083,40.6757483 40.6759307,42.1623083 38.8420811,42.1623083"
            id="Fill-1"
            fill="#1F2B2D"
          />
          <polyline
            id="Fill-2"
            fill="#00CBFF"
            points="10.6282659 22.8957606 21.0812454 33.3489225 31.5342249 22.8957606 24.5796775 15.9413956 28.0359752 12.4850979 24.5375431 8.98666584 17.5829957 15.9413956 24.5375431 22.8957606 21.0812454 26.3520583 14.126698 19.3975109 10.6282659 22.8957606"
          />
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
