const EnUs = {
    translations: {
        home: {
            header: {
                title: 'dojo',
                contact: 'Join the discussion',
                message: 'Tech hub for the NEO-blockchain',
            },
            banner: {
                subtitle: `If you're new, start here.`,
                title: 'Neo Quickstart for Developers',
                description:
                    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua consetetur sadipscing…',
                button: 'Read article',
            },
            footer: {
                title: 'COZ',
                copyright: ' Copyright ',
            },
        },
        article: {
            estimatedTime: 'min. reading time',
            by: 'by',
            lastUpdated: 'last updated',
            share: 'Share this Article',
            menu: {
                tools: 'Related Tools:',
                articles: 'Related Articles:',
                share: "Share this Article:",
                discuss: "Discuss this article"
            }
        }
    },
}
export default EnUs
