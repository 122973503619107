import React from 'react'
import { ClockIcon, FlagIcon, PeopleIcon, RedirectIcon } from '../icons'
import * as Styled from './styled'
import background from './background.png'
import backgroundMobile from './background-mobile.png'
import { Article } from '../../model/Article'
import { useHistory } from 'react-router'
import { Container, Description } from '../shared'
import * as Icons from '../icons/tools/index'
import { Tool } from '../../model/Tool'
import Translator from '../../i18n/translator'

const Banner: React.FC<{ article: Article }> = ({ article = {} }) => {
  const history = useHistory()
  return (
    <Styled.Container
      display="flex"
      justifyContent="center"
      height={['75vh', '75vh']}
      backgroundColor="background"
      backgroundImage={[`url(${backgroundMobile})`, `url(${background})`]}
      backgroundSize={['80%', '50%']}
      backgroundRepeat="no-repeat"
      backgroundPosition={['5em -3.5em', 'right 1em']}
      borderBottom="1.5em solid"
      borderColor="charcoalGrey"
      position="relative"
      zIndex={1}
    >
      <Styled.BoxLeft color="charcoalGrey" width="8%" height="100%">
        {article?.relatedTools?.map((i, index) => (
          <Container key={index} height={`${100 / (article?.relatedTools?.length || 1)}%`} width="100%" bg={i.color} />
        ))}
      </Styled.BoxLeft>

      <Styled.Content display="flex" alignItems="center" height="100%" width={[1 / 1.2, 1]}>
        <Styled.Container display="flex" width="100%">
          <Styled.Article marginLeft={['1em', '7em']} display="flex" flexDirection="column" alignItems="flex-start">
            <Container>
              {article?.relatedTools?.map((item: Tool, index: number) => {
                const Logo = item.imageSlug ? Icons[item.imageSlug] : undefined
                return !!Logo && <Logo key={index} width="2.75em" height="2.75em" padding="0.25em" mt="-3.25em" />
              })}
            </Container>
            <Styled.Line width={[1 / 1.1, 1 / 2.4]} backgroundColor="steelGrey" my={['.1em', '.5em']} height={0.5} />
            <Styled.Title
              width={[1 / 1.05, 1 / 2]}
              fontSize={['2em', '3em']}
              my=".2em"
              lineHeight="1em"
              fontFamily="SofiaProBold"
              textAlign="left"
            >
              {article.title}
            </Styled.Title>
            <Container display={['flex', 'none']} alignItems="center" marginBottom="1em">
              <ClockIcon width=".8em" height=".8em" mr="0.3em" />
              <Description fontSize={['.7em', '.8em']} color="blueyGrey" margin="0 0 0 .1em">
                {' '}
                {article.estimatedTime} {Translator('article.estimatedTime')}
              </Description>
            </Container>
            <Styled.Description textAlign="left" color="#545556" width={[1 / 1.1, 1 / 2.5]} my="0" mt=".5em" fontSize={['.7em', '1em']}>
              {article.briefSummary}
            </Styled.Description>

            <Container
              display="flex"
              alignItems="center"
              justifyContent={['flex-start', 'space-between']}
              borderBottom="1px solid"
              borderTop="1px solid"
              borderColor="steelGrey"
              width={[1 / 1.1, 1 / 2.4]}
              paddingY={['.8em', '1.3em']}
              marginY="1.8em"
            >
              <Container display="flex" alignItems="center">
                <PeopleIcon width="1.5em" height="1em" mr="0.25em" />
                <Description fontSize={['.8em', '.8em']} color="blueyGrey" margin="0 0 0 .1em">
                  {' '}
                  {article.targetAudience}{' '}
                </Description>
              </Container>
              <Container display="flex" alignItems="center" marginLeft={['1.5em', 0]}>
                <FlagIcon width="1em" height="1em" />
                <Description fontSize={['.8em', '.8em']} color="blueyGrey" margin="0 0 0 .1em">
                  {' '}
                  {article.level}{' '}
                </Description>
              </Container>
              <Container display={['none', 'flex']} alignItems="center">
                <ClockIcon width="1em" height="1em" mr="0.5em" />
                <Description fontSize={['.8em', '.8em']} color="blueyGrey" margin="0 0 0 .1em">
                  {' '}
                  {article.estimatedTime} {Translator('article.estimatedTime')}
                </Description>
              </Container>
            </Container>
            <Styled.CustomButton
              px="1.5em"
              backgroundColor="black"
              color="white"
              text="home.banner.button"
              onClick={() => history.push(`/article/${article.slug}`)}
              icon={() => <RedirectIcon width={['16px', '22px']} height={['16px', '22px']} ml="1em" />}
              fontSize={['.8em', '1em']}
              mt={[0, 20]}
              style={{ cursor: 'pointer' }}
            />
          </Styled.Article>
        </Styled.Container>
      </Styled.Content>
    </Styled.Container>
  )
}

export default Banner
