import styled from 'styled-components'
import {layout, space} from 'styled-system'

export const FlagIcon = styled(({ ...rest }) => (
  <svg width="9px" height="12px" viewBox="0 0 19 24" {...rest}>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="COZ---00---devex-page-V2" transform="translate(-498.000000, -1526.000000)" fill="#636467">
        <g id="Group-5" transform="translate(498.000000, 1526.000000)">
          <path d="M0.75,24 C0.336,24 0,23.664 0,23.25 L0,0.75 C0,0.336 0.336,0 0.75,0 C1.164,0 1.5,0.336 1.5,0.75 L1.5,23.25 C1.5,23.664 1.164,24 0.75,24" id="Fill-1"/>
          <path d="M17.0210203,1.5265625 L17.0421203,1.5265625 L17.0210203,1.5265625 Z M17.0000625,12.9999375 L0.75,12.9999375 C0.336,12.9999375 0,12.6639375 0,12.2499375 C0,11.8359375 0.336,11.4999375 0.75,11.4999375 L16.2469688,11.4999375 L11.43,7.87992188 C10.9929375,7.5380625 10.7499375,7.03804688 10.7499375,6.5000625 C10.7499375,5.9619375 10.9929375,5.46201563 11.4169688,5.13004688 L16.2469688,1.5 L0.75,1.5 C0.336,1.5 0,1.164 0,0.75 C0,0.336 0.336,0 0.75,0 L17.0000625,0 C17.6890781,0 18.2499375,0.561046875 18.2499375,1.2500625 C18.2499375,1.64503125 18.0710625,2.00498438 17.7610313,2.23992188 L12.3299531,6.3200625 C12.3090938,6.33801563 12.2499375,6.399 12.2499375,6.501 C12.2499375,6.60295313 12.3090938,6.6639375 12.3429375,6.69103125 L17.7599531,10.7609063 C18.0719531,10.9949531 18.2499375,11.3560313 18.2499375,11.751 C18.2499375,12.4390781 17.6890781,12.9999375 17.0000625,12.9999375 L17.0000625,12.9999375 Z" id="Fill-3"/>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
