import React from 'react'
import { Article } from '../../model/Article'
import { Container, Text } from '../shared'
import * as Styled from './styled'
import { Tool } from '../../model/Tool'
import * as Icons from '../icons/tools/index'
import { ClockIcon, FlagIcon, PeopleIcon } from '../icons'
import { useHistory } from 'react-router'

const Tools: React.FC<{ tools: Tool[] }> = ({ tools }) => {
  return (
    <Container width="100%" display="flex">
      {tools.map((tool, i) => {
        const Logo = tool?.imageSlug ? Icons[tool.imageSlug] : undefined

        return (
          <Container key={i} width="100%" height="3.25em" mt="-1.625em" display="flex" flexDirection="column">
            <Styled.Line bg={tool?.color} width="100%" height="1.625em" mt="auto" />
            {Logo ? (
              <Logo width="2.75em" height="2.75em" padding="0.25em" mt="-3.25em" />
            ) : (
              <Container bg={tool?.color} width="2.75em" height="2.75em" padding="0.25em" mt="-3.25em" />
            )}
          </Container>
        )
      })}
    </Container>
  )
}

const ArticleCard: React.FC<{
  article: Article
  height?: number | string
  width?: number | string
  margin?: number | string
}> = ({ article, width = '20.5em', height = '16em', margin = '2.25em' }) => {
  const history = useHistory()
  const navigation = () => {
    history.push(`/article/${article.slug}`)
  }
  return (
    <Styled.Card
      width={width}
      height={height}
      marginLeft={'auto'}
      marginRight={'auto'}
      marginTop={margin}
      marginBottom={margin}
      bg="paleGrey"
      display="flex"
      flexDirection="column"
      onClick={navigation}
    >
      <Tools tools={article.relatedTools} />
      <Container px="1.5em" py="1.25em" display="flex" flexDirection="column" flexGrow="1">
        <Container mb="0.25em" display="flex" flexDirection="row" alignItems="flex-end">
          <ClockIcon width="1.5em" height="1.5em" mr="0.5em" />
          <Text fontSize="1em" color="blueyGrey" fontWeight="500">
            {article.estimatedTime} min. reading time
          </Text>
        </Container>
        <Text fontSize="1.5em" fontWeight="600" flexGrow="1" color="black" lineHeight="1.3em">
          {article.title}
        </Text>
        <Styled.Line width="100%" height="0.5px" bg="warmGrey" my="1.25em" />
        <Container width="100%" display="flex" flexDirection="row">
          <Container display="flex" flexDirection="row" flexGrow={1} alignItems="center">
            <PeopleIcon width="2.25em" height="1.5em" mr="0.25em" />
            <Text fontSize="0.875em" color="blueyGrey" fontWeight="500">
              {article.targetAudience}
            </Text>
          </Container>
          <Container display="flex" flexDirection="row" flexGrow={1} alignItems="center">
            <FlagIcon width="1.125em" height="1.5em" mr="0.25em" />
            <Text fontSize="0.875em" color="blueyGrey" fontWeight="500">
              {article.level}
            </Text>
          </Container>
        </Container>
      </Container>
    </Styled.Card>
  )
}
export default ArticleCard
