import styled from 'styled-components'
import {layout, space} from 'styled-system'

export const ClockIcon = styled(({ ...rest }) => (
  <svg width="12px" height="12px" viewBox="0 0 24 24" {...rest}>
    <g fill="none" fillRule="evenodd">
      <g fill="#636466">
        <g>
          <path d="M19.25 13.25H11.5c-.414 0-.75-.336-.75-.75V4.75c0-.414.336-.75.75-.75s.75.336.75.75v7h7c.414 0 .75.336.75.75s-.336.75-.75.75" transform="translate(-735 -1361) translate(735 1361)"/>
          <path d="M12 1.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12" transform="translate(-735 -1361) translate(735 1361)"/>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
