import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const ArrowLeft = styled(({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...rest}>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g transform="translate(-23 -123) translate(24 124)">
          <circle cx="15" cy="15" r="15" stroke="#979797" />
          <path
            fill="#000"
            d="M21.875 14.195H9.407l4.774-4.502c.25-.235.26-.627.022-.875-.238-.247-.634-.257-.884-.02l-5.453 5.14c-.236.235-.366.545-.366.876 0 .33.13.641.377.885l5.442 5.132c.121.114.276.17.431.17.165 0 .33-.064.453-.192.238-.247.228-.639-.022-.874l-4.794-4.502h12.488c.345 0 .625-.277.625-.619 0-.342-.28-.619-.625-.619"
          />
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
