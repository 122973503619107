import styled from 'styled-components'
import {layout, space} from 'styled-system'

export const PeopleIcon = styled(({ ...rest }) => (
  <svg width="18px" height="12px" viewBox="0 0 37 24" {...rest}>
    <defs>
      <polygon id="path-1" points="0 0.0306301478 36.4137647 0.0306301478 36.4137647 14.8965375 0 14.8965375"/>
    </defs>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="COZ---00---devex-page-V2" transform="translate(-334.000000, -1526.000000)">
        <g id="Group-10" transform="translate(352.206897, 1538.000000) scale(-1, 1) translate(-352.206897, -1538.000000) translate(334.000000, 1526.000000)">
          <path d="M18.2069311,6.86843492 C16.7013193,6.86843492 15.4763927,5.64350825 15.4763927,4.13789654 C15.4763927,2.63228482 16.7013193,1.40742715 18.2069311,1.40742715 C19.7125428,1.40742715 20.9374004,2.63235382 20.9374004,4.13789654 C20.9374004,5.64350825 19.7125428,6.86843492 18.2069311,6.86843492 M18.2069311,0 C15.9252266,0 14.0689655,1.85626103 14.0689655,4.13789654 C14.0689655,6.41960103 15.9252266,8.27586207 18.2069311,8.27586207 C20.4885666,8.27586207 22.3448276,6.41960103 22.3448276,4.13789654 C22.3448276,1.85626103 20.4885666,0 18.2069311,0" id="Fill-1" fill="#414141"/>
          <path d="M8.27586207,9.38841749 C7.20604363,9.38841749 6.33572044,8.5180943 6.33572044,7.44827586 C6.33572044,6.37845742 7.20604363,5.50813423 8.27586207,5.50813423 C9.34568051,5.50813423 10.2160037,6.37852459 10.2160037,7.44827586 C10.2160037,8.51802713 9.34568051,9.38841749 8.27586207,9.38841749 M8.27586207,4.13793103 C6.45051417,4.13793103 4.96551724,5.62292796 4.96551724,7.44827586 C4.96551724,9.27362376 6.45051417,10.7586207 8.27586207,10.7586207 C10.10121,10.7586207 11.5862069,9.27362376 11.5862069,7.44827586 C11.5862069,5.62292796 10.1011428,4.13793103 8.27586207,4.13793103" id="Fill-3" fill="#414141"/>
          <path d="M28.1379646,9.38841749 C27.0681353,9.38841749 26.1978033,8.5180943 26.1978033,7.44827586 C26.1978033,6.37845742 27.0681353,5.50813423 28.1379646,5.50813423 C29.2077267,5.50813423 30.0780588,6.37852459 30.0780588,7.44827586 C30.0780588,8.51802713 29.2077267,9.38841749 28.1379646,9.38841749 M28.1379646,4.13793103 C26.3126654,4.13793103 24.8275862,5.62292796 24.8275862,7.44827586 C24.8275862,9.27362376 26.3126654,10.7586207 28.1379646,10.7586207 C29.963331,10.7586207 31.4482759,9.27362376 31.4482759,7.44827586 C31.4482759,5.62292796 29.9632639,4.13793103 28.1379646,4.13793103" id="Fill-5" fill="#414141"/>
          <g id="Group-9" transform="translate(0.000000, 9.103448)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"/>
            </mask>
            <g id="Clip-8"/>
            <path d="M32.1980858,13.4495149 L24.6572302,13.4495149 L24.6572302,7.92608631 C24.7600707,5.94207842 26.4107819,4.35949714 28.4250621,4.35949714 C30.5054845,4.35949714 32.1980858,6.04770207 32.1980858,8.12272079 L32.1980858,13.4495149 Z M23.2013185,13.4495858 L13.2073681,13.4495858 L13.2073681,6.46799764 C13.2073681,3.71624985 15.448381,1.47758187 18.2030276,1.47758187 C20.9620125,1.47758187 23.2064392,3.71624985 23.2064392,6.46799764 L23.2013185,13.4495858 Z M11.7567194,13.4495858 L4.21813966,13.4495858 L4.21813966,8.12279172 C4.21813966,6.047773 5.91074095,4.35956808 7.99123448,4.35956808 C10.0675319,4.35956808 11.7567194,6.047773 11.7567194,8.12279172 L11.7567194,13.4495858 L11.7567194,13.4495858 Z M35.6884047,13.4495858 L33.6488767,13.4495858 L33.6488767,8.12279172 C33.6488767,5.24988532 31.30545,2.91254542 28.4249909,2.91254542 C26.8232819,2.91254542 25.3882797,3.63595034 24.4294306,4.77170601 C23.6810987,2.0425868 21.1733832,0.0306301478 18.2030276,0.0306301478 C15.2352323,0.0306301478 12.7299349,2.04386365 11.9835233,4.77440158 C11.0259543,3.63708532 9.59201897,2.91254542 7.9913056,2.91254542 C5.11091767,2.91254542 2.76749095,5.24988532 2.76749095,8.12279172 L2.76749095,13.4495858 L0.725402586,13.4495858 C0.324708621,13.4495858 -2.84482759e-05,13.7734794 -2.84482759e-05,14.1730617 C-2.84482759e-05,14.5726439 0.324708621,14.8965375 0.725402586,14.8965375 L35.6884047,14.8965375 C36.0889565,14.8965375 36.4137647,14.5726439 36.4137647,14.1730617 C36.4137647,13.7734794 36.0889565,13.4495858 35.6884047,13.4495858 L35.6884047,13.4495858 Z" id="Fill-7" fill="#414141" mask="url(#mask-2)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`

