import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const Dora = styled(({ article, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={article ? '80' : '100'} height={'41'} viewBox="0 0 119 41" {...rest}>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#1F2B2D"
              d="M36.85 40H3.15C1.41 40 0 38.59 0 36.85V3.15C0 1.41 1.41 0 3.15 0h33.7C38.59 0 40 1.41 40 3.15v33.7c0 1.74-1.41 3.15-3.15 3.15"
              transform="translate(-179 -190) translate(179 190.203)"
            />
            <path
              fill="#D355E7"
              d="M16.712 6.804L6.835 16.681 10.114 19.96 19.991 10.083 16.712 6.804M26.629 10.083L29.908 13.362 13.393 29.877 10.114 26.598 26.629 10.083M29.908 20L33.227 23.319 23.35 33.196 20.031 29.877 29.908 20"
              transform="translate(-179 -190) translate(179 190.203)"
            />
          </g>
          <path
            fill="#1F2B2D"
            d="M63.657 20.122c0-2.761-2.242-4.374-4.348-4.374-2.324 0-4.183 1.804-4.183 4.374 0 2.543 1.86 4.457 4.183 4.457 2.297 0 4.348-1.722 4.348-4.457zm2.378-13.315v19.741h-2.378v-2.023c-.848 1.476-2.707 2.296-4.54 2.296-3.499 0-6.397-2.652-6.397-6.671 0-4.047 2.87-6.644 6.37-6.644 1.914 0 3.72.765 4.567 2.27v-8.97h2.378zM83.588 20.122c0-2.624-1.969-4.374-4.32-4.374-2.324 0-4.266 1.75-4.266 4.374 0 2.653 1.942 4.457 4.266 4.457 2.351 0 4.32-1.804 4.32-4.457zm-10.992 0c0-4.046 3.035-6.616 6.672-6.616 3.636 0 6.699 2.57 6.699 6.616 0 4.047-3.063 6.7-6.7 6.7-3.636 0-6.67-2.653-6.67-6.7zM100.758 13.998l-.383 2.242c-.683-.328-1.367-.437-1.86-.437-2.542 0-3.554 2.706-3.554 6.07v4.675h-2.406V13.779h2.406v2.898c.657-2.242 1.887-3.171 3.582-3.171.902 0 1.832.246 2.215.492M116.124 20.122c0-2.761-2.242-4.374-4.348-4.374-2.324 0-4.183 1.804-4.183 4.374 0 2.543 1.86 4.457 4.183 4.457 2.297 0 4.348-1.722 4.348-4.457zm2.378-6.343v12.769h-2.378v-2.023c-.848 1.476-2.707 2.296-4.539 2.296-3.5 0-6.398-2.652-6.398-6.671 0-4.047 2.871-6.644 6.37-6.644 1.915 0 3.72.765 4.567 2.27v-1.997h2.378z"
            transform="translate(-179 -190) translate(179 190.203)"
          />
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`

export const DoraLogo = styled(({ ...rest }) => (
  <svg width="43px" height="43px" viewBox="0 0 43 43" {...rest}>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="COZ---00---devex-page-V2" transform="translate(-715.000000, -1294.000000)">
        <g id="Group-28-Copy" transform="translate(715.000000, 1294.000000)">
          <path
            d="M38.8542169,42.1754815 L3.32144712,42.1754815 C1.4870245,42.1754815 1.037148e-13,40.688457 1.037148e-13,38.8540344 L1.037148e-13,3.32144712 C1.037148e-13,1.4870245 1.4870245,1.037148e-13 3.32144712,1.037148e-13 L38.8542169,1.037148e-13 C40.6886395,1.037148e-13 42.1754815,1.4870245 42.1754815,3.32144712 L42.1754815,38.8540344 C42.1754815,40.688457 40.6886395,42.1754815 38.8542169,42.1754815"
            id="Fill-1"
            fill="#1F2B2D"
          />
          <polyline
            id="Fill-2"
            fill="#D355E7"
            points="17.6211491 7.174209 7.20705126 17.5884893 10.6644288 21.0456844 21.0785267 10.6315866 17.6211491 7.174209"
          />
          <polyline
            id="Fill-3"
            fill="#D355E7"
            points="28.0773945 10.6315866 31.5347721 14.0889642 14.1218064 31.5019298 10.6644288 28.0445523 28.0773945 10.6315866"
          />
          <polyline
            id="Fill-4"
            fill="#D355E7"
            points="31.5347721 21.087832 35.0341148 24.5873571 24.6201994 35.001455 21.1206743 31.5019298 31.5347721 21.087832"
          />
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
