import React from 'react'
import Translator from '../../../i18n/translator'
import * as Styled from './styled'

interface Type {
  text: string
  color?: string
  backgroundColor?: string
  icon?: React.FC
  onClick?: () => void
}

const Button: React.FC<Type> = ({ text, color, onClick, icon: Icon, backgroundColor, ...rest }) => {
  return (
    <Styled.JoinButton
      onClick={onClick}
      display="flex"
      alignItems="center"
      padding="0.6em 1em"
      borderRadius="5px"
      fontFamily="SofiaProSemibold"
      color={color}
      backgroundColor={backgroundColor}
      rel="noreferrer"
      {...rest}
    >
      {Translator(text)} {!!Icon && <Icon />}
    </Styled.JoinButton>
  )
}

export default Button
