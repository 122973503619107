import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const RedditIcon = styled(({ ...rest }) => (
  <svg width="42" height="42" viewBox="0 0 42 42" {...rest}>
    <defs>
      <path id="4ma319fzoa" d="M0 0L27 0 27 22 0 22z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-1165 -895) translate(942 861) translate(224 35)">
            <circle cx="20" cy="20" r="20" stroke="#000" />
            <g>
              <path
                fill="#231F20"
                d="M10 11c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2"
                transform="translate(6 9)"
              />
              <g transform="translate(6 9)">
                <mask id="dhws7ob0xb" fill="#fff">
                  <use href="#4ma319fzoa" />
                </mask>
                <path
                  fill="#231F20"
                  d="M25.076 12.897c-.336-1.562-1.334-2.977-2.799-4.107.247-.082.505-.137.779-.137 1.419 0 2.575 1.179 2.575 2.63-.002.61-.212 1.168-.555 1.614m-11.505 7.775c-5.666 0-10.274-2.97-10.274-6.62s4.608-6.62 10.274-6.62c5.666 0 10.273 2.97 10.273 6.62s-4.607 6.62-10.273 6.62M3.944 8.653c.31 0 .603.065.88.168-1.49 1.16-2.49 2.614-2.792 4.221-.411-.468-.666-1.082-.666-1.759 0-1.45 1.158-2.63 2.578-2.63m17.835-7.322c.94 0 1.707.744 1.707 1.659 0 .914-.767 1.658-1.707 1.658-.944 0-1.708-.744-1.708-1.658 0-.915.767-1.659 1.708-1.659M27 11.283c0-2.184-1.769-3.961-3.944-3.961-.764 0-1.473.224-2.076.601-1.872-1.056-4.23-1.712-6.81-1.801l1.486-4.145 3.078.717c-.01.098-.03.193-.03.295 0 1.647 1.38 2.988 3.077 2.988 1.694 0 3.077-1.34 3.077-2.988 0-1.65-1.382-2.99-3.077-2.99-1.107 0-2.068.574-2.61 1.426l-4.4-1.022-2.054 5.726c-2.566.128-4.9.824-6.737 1.907.037-.024.068-.053.105-.077-.618-.402-1.35-.638-2.14-.638C1.769 7.321 0 9.097 0 11.282c0 1.46.8 2.728 1.978 3.411C2.461 18.777 7.471 22 13.573 22c6.14 0 11.176-3.266 11.603-7.387 1.094-.7 1.824-1.93 1.824-3.33"
                  mask="url(#dhws7ob0xb)"
                />
              </g>
              <path
                fill="#231F20"
                d="M18 11c1.105 0 2 .896 2 2s-.895 2-2 2c-1.104 0-2-.896-2-2s.896-2 2-2M16.97 16.129c-3.74 2.896-6.756.168-6.89.044-.287-.27-.704-.217-.933.117-.23.338-.186.827.1 1.096C9.27 17.41 10.995 19 13.498 19c1.245 0 2.683-.394 4.203-1.57.306-.237.391-.72.19-1.078-.204-.36-.616-.46-.921-.223"
                transform="translate(6 9)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
