import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const Mamba = styled(({ article, ...rest }) => (
  <svg width={article ? '113' : '130'} height="40" viewBox="0 0 160 40" {...rest}>
    <defs>
      <path id="61dm5hclaa" d="M0.01 0.145L39.626 0.145 39.626 39.771 0.01 39.771z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-1289 -190) translate(1289 190)">
              <mask id="l8wvtu8j9b" fill="#fff">
                <use href="#61dm5hclaa" />
              </mask>
              <path
                fill="#1F2B2D"
                d="M36.506 39.771H3.12C1.397 39.771 0 38.374 0 36.651V3.266C0 1.542 1.397.146 3.12.146h33.386c1.723 0 3.12 1.396 3.12 3.12V36.65c0 1.723-1.397 3.12-3.12 3.12"
                mask="url(#l8wvtu8j9b)"
              />
            </g>
            <path
              fill="#E9BE38"
              d="M19.813 13.383L16.565 10.134 6.741 19.958 16.525 29.743 19.813 26.455 13.277 19.919 19.813 13.383M19.813 13.383L23.062 10.134 32.886 19.958 23.101 29.743 19.813 26.455 26.35 19.919 19.813 13.383"
              transform="translate(-1289 -190) translate(1289 190)"
            />
          </g>
          <path
            fill="#1F2B2D"
            d="M72.998 19.057v7.313h-2.356v-7.178c0-2.113-1.165-3.467-3.007-3.467-2.248-.027-3.602 1.869-3.386 4.307l-.027-.109v6.447h-2.356v-7.178c0-2.14-1.165-3.467-2.844-3.467-1.788 0-3.576 1.11-3.576 3.792v6.853H53.09V13.72h2.356v1.924c.65-1.355 2.276-2.194 4.063-2.194 1.842 0 3.44.948 4.171 2.627 1.138-2.248 3.143-2.627 4.416-2.627 3.169 0 4.902 2.058 4.902 5.607M90.17 20.005c0-2.736-2.221-4.334-4.307-4.334-2.302 0-4.144 1.787-4.144 4.334 0 2.519 1.842 4.415 4.144 4.415 2.275 0 4.307-1.707 4.307-4.415zm2.357-6.284V26.37H90.17v-2.005c-.84 1.463-2.682 2.276-4.497 2.276-3.466 0-6.338-2.628-6.338-6.61 0-4.008 2.844-6.581 6.312-6.581 1.896 0 3.683.758 4.523 2.248V13.72h2.357zM119.802 19.057v7.313h-2.357v-7.178c0-2.113-1.164-3.467-3.006-3.467-2.249-.027-3.603 1.869-3.386 4.307l-.027-.109v6.447h-2.357v-7.178c0-2.14-1.165-3.467-2.844-3.467-1.788 0-3.575 1.11-3.575 3.792v6.853h-2.357V13.72h2.357v1.924c.65-1.355 2.275-2.194 4.063-2.194 1.842 0 3.44.948 4.171 2.627 1.138-2.248 3.142-2.627 4.415-2.627 3.17 0 4.903 2.058 4.903 5.607M137.786 20.005c0-2.547-1.842-4.334-4.171-4.334-2.059 0-4.28 1.598-4.28 4.334 0 2.708 2.032 4.415 4.28 4.415 2.33 0 4.171-1.896 4.171-4.415zm2.384.027c0 3.981-2.871 6.609-6.339 6.609-1.814 0-3.656-.813-4.496-2.276v2.005h-2.356V6.813h2.356v8.885c.84-1.49 2.627-2.248 4.523-2.248 3.468 0 6.312 2.573 6.312 6.582zM156.665 20.005c0-2.736-2.221-4.334-4.307-4.334-2.302 0-4.144 1.787-4.144 4.334 0 2.519 1.842 4.415 4.144 4.415 2.275 0 4.307-1.707 4.307-4.415zm2.356-6.284V26.37h-2.356v-2.005c-.84 1.463-2.682 2.276-4.497 2.276-3.467 0-6.338-2.628-6.338-6.61 0-4.008 2.844-6.581 6.311-6.581 1.896 0 3.684.758 4.524 2.248V13.72h2.356z"
            transform="translate(-1289 -190) translate(1289 190)"
          />
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`

export const MambaLogo = styled(({ ...rest }) => (
  <svg width="43px" height="43px" viewBox="0 0 43 43" {...rest}>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="COZ---00---devex-page-V2" transform="translate(-1225.000000, -1294.000000)">
        <g id="Group-23-Copy" transform="translate(1225.000000, 1294.000000)">
          <path
            d="M38.8420811,42.1623083 L3.32040969,42.1623083 C1.48656004,42.1623083 -8.64019956e-14,40.6757483 -8.64019956e-14,38.8418987 L-8.64019956e-14,3.32040969 C-8.64019956e-14,1.48656004 1.48656004,-8.64019956e-14 3.32040969,-8.64019956e-14 L38.8420811,-8.64019956e-14 C40.6759307,-8.64019956e-14 42.1623083,1.48656004 42.1623083,3.32040969 L42.1623083,38.8418987 C42.1623083,40.6757483 40.6759307,42.1623083 38.8420811,42.1623083"
            id="Fill-1"
            fill="#1F2B2D"
          />
          <polyline
            id="Fill-4"
            fill="#E9BE38"
            points="21.0812454 14.0845636 17.6251301 10.6282659 7.17196819 21.0812454 17.5829957 31.4920905 21.0812454 27.9936584 14.126698 21.039111 21.0812454 14.0845636"
          />
          <polyline
            id="Fill-5"
            fill="#E9BE38"
            points="21.0812454 14.0845636 24.5375431 10.6282659 34.9905226 21.0812454 24.5796775 31.4920905 21.0812454 27.9936584 28.0359752 21.039111 21.0812454 14.0845636"
          />
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
