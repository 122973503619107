import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Translator from '../../i18n/translator'
import { Boa, Dora, Mamba, MenuIcon, Neon, RedirectIcon } from '../icons'
import * as Styled from './styled'

const Header: React.FC = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const article = location.pathname.slice(0, 8) === '/article'
  const verifyBar = article || open
  return (
    <Styled.Wrapper position="relative" zIndex={2}>
      <Styled.Container
        width="100%"
        backgroundColor="background"
        padding={['4% 7.5% .8%', '.8% 7.5%']}
        height={[open ? '100vh' : 'initial', 'initial']}
        position={[open ? 'fixed' : 'initial', 'initial']}
        display="flex"
        zIndex="2"
        flexDirection="column"
        alignItems="center"
      >
        <Styled.Container display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Styled.Group display="flex" alignItems="center">
            <a href="https://coz.io/" target="_blank" rel="noreferrer">
              <Styled.Logo width={['4.5em', '7.8em']} />
            </a>
            <Link to="/">
              <Styled.Title marginLeft=".7em" my="0" fontSize={['1.2em', '1.8em']} fontFamily="SofiaProBold">
                {Translator('home.header.title')}
              </Styled.Title>
            </Link>
          </Styled.Group>
          <Styled.CustomButton
            text="home.header.contact"
            icon={() => <RedirectIcon width={['16px', '22px']} height={['16px', '22px']} ml="1em" />}
            backgroundColor="grapefruit"
            color="white"
            display={['none', 'flex']}
            href="https://forum.coz.io/"
            target="_blank"
            rel="noreferrer"
          />
          <Styled.ButtonMobile
            display={['flex', 'none']}
            alignItems="center"
            justifyContent="center"
            backgroundColor={open && 'black'}
            onClick={() => setOpen(!open)}
            padding="1%"
            width="40px"
            height="40px"
            borderRadius="50%"
          >
            <MenuIcon color={open && 'white'} />
          </Styled.ButtonMobile>
        </Styled.Container>
        <ContainerLinks open={open} mobile />
        <Styled.CustomButton
          text="home.header.contact"
          icon={() => <RedirectIcon width={['22px', '22px']} height={['22px', '22px']} ml="1em" />}
          backgroundColor="grapefruit"
          color="white"
          display={[open ? 'flex' : 'none', 'none']}
          href="https://forum.coz.io/"
          rel="noreferrer"
          target="_blank"
          position="fixed"
          bottom="4em"
          width="80%"
          justifyContent="center"
        />
      </Styled.Container>
      <Styled.ContainerDescription
        display={[verifyBar ? 'none' : 'inherit', 'inherit']}
        py=".1%"
        backgroundColor="charcoalGrey"
        position="relative"
        boxShadow={['0 6px 12px 0 rgba(0, 0, 0, 0.2)', 'none']}
      >
        <Styled.TextDescription color="white" fontSize={['.8em', '1em']} textAlign="center" fontFamily="SofiaProLight">
          {Translator('home.header.message')}
        </Styled.TextDescription>
      </Styled.ContainerDescription>
      <ContainerLinks />
    </Styled.Wrapper>
  )
}

interface TypeNavigate {
  component: React.FC
  url: string
  borderTop?: boolean
}

const NavIcon = ({ component: Logo, url, borderTop }: TypeNavigate) => {
  return (
    <Styled.Link
      width={[1, 'initial']}
      borderBottom={['.1px solid', 'none']}
      borderTop={borderTop && ['.2px solid', 'none']}
      py={['.5em', '0']}
      borderColor="#979797"
      display="flex"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <Logo />
    </Styled.Link>
  )
}

const ContainerLinks = ({ open, mobile }: any) => (
  <Styled.ContainerLinks
    display={[open ? 'flex' : 'none', mobile ? 'none' : 'flex']}
    justifyContent="space-between"
    alignItems={['flex-start', 'center']}
    flexWrap="wrap"
    flexDirection={['column', 'row']}
    backgroundColor="background"
    padding={['0', '0.5% 7.5%']}
    boxShadow={['none', '0 6px 12px 0 rgba(0, 0, 0, 0.2)']}
    position="relative"
    marginTop={['1em', 0]}
    width="100%"
  >
    <NavIcon component={Dora} url="https://dora.coz.io/documentation/index.html" borderTop />
    <NavIcon component={Neon} url="https://docs.coz.io/neo3/neon-js" />
    <NavIcon component={Mamba} url="https://docs.coz.io/neo3/mamba/index.html" />
    <NavIcon component={Boa} url="https://docs.coz.io/neo3/boa/index.html" />
  </Styled.ContainerLinks>
)

export default Header
