import styled from 'styled-components'
import { layout, space, typography } from 'styled-system'
import { LogoCOZ } from '../../components/icons'


export const Logo = styled(LogoCOZ).attrs(() => { })`
  ${layout}
`

export const Title = styled.h2.attrs(() => { })`
  ${space}
  ${typography}
`