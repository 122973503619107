import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomeScreen from './pages/home'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import { GlobalProvider } from './context/GlobalContext'
import './i18n'
import Header from './components/header'
import Footer from './components/footer'
import ArticleScreen from './pages/article'
import { ToastProvider } from 'react-toast-notifications'

let timeout = undefined

const App: React.FC = () => {
  const [load, setLoad] = useState(false)
  useEffect(() => {
    timeout = setTimeout(() => {
      setLoad(true)
    }, 1)
    if (load) {
      return clearTimeout(timeout)
    }
  })
  return (
    <GlobalProvider>
      <ThemeProvider theme={theme}>
        <ToastProvider  >
          <Router>
            <Header />
            <Switch>
              <Route exact path="/" component={HomeScreen} />
              {load && <Route exact path="/article/:slug" component={ArticleScreen} />}
            </Switch>
            <Footer />
          </Router>
        </ToastProvider>
      </ThemeProvider>
    </GlobalProvider>
  )
}

export default App
