import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const Neon = styled(({ article, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={article ? '106' : '130'} height="41" viewBox="0 0 158 41" {...rest}>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#1F2B2D"
              d="M36.85 40H3.15C1.41 40 0 38.59 0 36.85V3.15C0 1.41 1.41 0 3.15 0h33.7C38.59 0 40 1.41 40 3.15v33.7c0 1.74-1.41 3.15-3.15 3.15"
              transform="translate(-714 -190) translate(714 190.203)"
            />
            <path
              fill="#A071EE"
              d="M23.436 19.986L20.169 23.274 13.58 16.665 10.243 19.986 20.161 29.904 30.095 19.986 26.772 16.671"
              transform="translate(-714 -190) translate(714 190.203)"
            />
            <path
              fill="#A071EE"
              d="M16.854 13.393L20.173 16.712 23.492 13.393 20.173 10.074 16.854 13.393"
              transform="translate(-714 -190) translate(714 190.203)"
            />
          </g>
          <path
            fill="#1F2B2D"
            d="M65.325 19.165v7.383h-2.38v-7.246c0-2.132-1.312-3.445-3.089-3.445s-3.91 1.012-3.91 3.773v6.918h-2.379V13.779h2.38v1.941c.765-1.558 2.843-2.214 4.237-2.214 3.172 0 5.168 2.078 5.14 5.66M82.221 19.138c-.3-2.187-1.859-3.39-3.964-3.39-2.16 0-3.691 1.093-4.074 3.39h8.038zm2.324 2.05h-10.39c.356 2.27 1.996 3.391 4.184 3.391 1.558 0 3.007-.656 3.718-1.941l2.078.82c-1.12 2.215-3.418 3.363-5.878 3.363-3.637 0-6.562-2.652-6.562-6.699 0-4.046 2.925-6.616 6.562-6.616 3.636 0 6.343 2.57 6.343 6.616 0 .356-.027.711-.055 1.067zM101.223 20.122c0-2.624-1.968-4.374-4.32-4.374-2.324 0-4.265 1.75-4.265 4.374 0 2.653 1.941 4.457 4.265 4.457 2.352 0 4.32-1.804 4.32-4.457zm-10.991 0c0-4.046 3.035-6.616 6.671-6.616 3.637 0 6.7 2.57 6.7 6.616 0 4.047-3.063 6.7-6.7 6.7-3.636 0-6.671-2.653-6.671-6.7zM121.948 19.165v7.383h-2.38v-7.246c0-2.132-1.311-3.445-3.089-3.445-1.777 0-3.91 1.012-3.91 3.773v6.918h-2.378V13.779h2.378v1.941c.766-1.558 2.844-2.214 4.238-2.214 3.172 0 5.168 2.078 5.14 5.66M128.7 25.18c0-.984.793-1.694 1.723-1.694.957 0 1.777.71 1.777 1.695 0 .957-.82 1.64-1.777 1.64-.93 0-1.723-.683-1.723-1.64M138.871 9.076c0-.902.766-1.586 1.668-1.586.93 0 1.695.684 1.695 1.586 0 .903-.765 1.586-1.695 1.586-.902 0-1.668-.683-1.668-1.586zm-3.336 21.436h1.45c2.105 0 2.406-1.75 2.378-3.253v-13.48h2.379v13.507c0 3.172-1.12 5.469-4.566 5.469h-1.64v-2.243zM148.14 22.747h2.214c.027 1.094.984 1.996 2.543 1.996 1.613 0 2.57-.738 2.57-1.777 0-.957-.738-1.449-1.75-1.668l-1.75-.41c-2.132-.574-3.363-1.613-3.363-3.664 0-2.105 1.805-3.718 4.375-3.718 2.16 0 4.402 1.148 4.402 3.718h-2.297c-.027-.984-1.039-1.64-2.05-1.64-1.23 0-2.05.738-2.05 1.722 0 .875.82 1.34 1.64 1.531l1.886.465c2.652.657 3.336 2.242 3.336 3.691 0 2.38-2.297 3.828-4.949 3.828-2.297 0-4.648-1.449-4.758-4.074"
            transform="translate(-714 -190) translate(714 190.203)"
          />
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`

export const NeonLogo = styled(({ ...rest }) => (
  <svg width="43px" height="43px" viewBox="0 0 43 43" {...rest}>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="COZ---00---devex-page-V2" transform="translate(-879.000000, -1294.000000)">
        <g id="Group-48" transform="translate(879.000000, 1294.000000)">
          <path
            d="M38.8420786,42.1623057 L3.32040948,42.1623057 C1.48655995,42.1623057 1.70103929e-13,40.6757458 1.70103929e-13,38.8418962 L1.70103929e-13,3.32040948 C1.70103929e-13,1.48655995 1.48655995,1.70103929e-13 3.32040948,1.70103929e-13 L38.8420786,1.70103929e-13 C40.6759282,1.70103929e-13 42.1623057,1.48655995 42.1623057,3.32040948 L42.1623057,38.8418962 C42.1623057,40.6757458 40.6759282,42.1623057 38.8420786,42.1623057"
            id="Fill-1"
            fill="#1F2B2D"
          />
          <polyline
            id="Fill-2"
            fill="#A071EE"
            points="24.7029783 21.0664697 21.2594489 24.5322519 14.3136571 17.5656666 10.7968028 21.0664697 21.2506937 31.5203605 31.7222773 21.0664697 28.2192854 17.5724154"
          />
          <polyline
            id="Fill-3"
            fill="#A071EE"
            points="17.7653946 14.1173947 21.2636441 17.6156442 24.7620759 14.1173947 21.2636441 10.6189628 17.7653946 14.1173947"
          />
        </g>
      </g>
    </g>
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
