import styled from 'styled-components'
import { layout, space } from 'styled-system'

export const ShareIcon = styled(({ iconColor, ...rest }) => (
  <svg width="22" height="26" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35 2.8C32.685 2.8 30.8 4.683 30.8 7C30.8 9.317 32.685 11.2 35 11.2C37.315 11.2 39.2 9.317 39.2 7C39.2 4.683 37.315 2.8 35 2.8ZM35 14C31.142 14 28 10.86 28 7C28 3.14 31.142 0 35 0C38.859 0 42 3.14 42 7C42 10.86 38.859 14 35 14ZM35 34.8C32.685 34.8 30.8 36.683 30.8 39C30.8 41.317 32.685 43.2 35 43.2C37.315 43.2 39.2 41.317 39.2 39C39.2 36.683 37.315 34.8 35 34.8ZM35 46C31.142 46 28 42.86 28 39C28 35.14 31.142 32 35 32C38.859 32 42 35.14 42 39C42 42.86 38.859 46 35 46ZM7 18.8C4.685 18.8 2.8 20.683 2.8 23C2.8 25.317 4.685 27.2 7 27.2C9.315 27.2 11.2 25.317 11.2 23C11.2 20.683 9.315 18.8 7 18.8ZM7 30C3.141 30 0 26.86 0 23C0 19.14 3.141 16 7 16C10.859 16 14 19.14 14 23C14 26.86 10.859 30 7 30Z"
      fill={iconColor || '#231F20'}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.393 22C11.908 22 11.437 21.74 11.183 21.276C10.803 20.587 11.034 19.71 11.703 19.318L28.92 9.191C29.586 8.795 30.438 9.036 30.816 9.727C31.196 10.417 30.964 11.293 30.296 11.685L13.08 21.812C12.863 21.939 12.628 22 12.394 22H12.393ZM29.607 37C29.374 37 29.137 36.939 28.921 36.81L11.702 26.685C11.034 26.293 10.802 25.416 11.182 24.727C11.562 24.036 12.412 23.797 13.079 24.191L30.297 34.317C30.965 34.71 31.197 35.587 30.817 36.275C30.561 36.741 30.09 37.001 29.607 37.001"
      fill={iconColor || '#231F20'}
    />
  </svg>
)).attrs(() => {})`
  ${layout}
  ${space}
`
